import { Button } from "@wargostec/react-components";
import React from "react";
import { CiSearch } from "react-icons/ci";
import { GiBroom } from "react-icons/gi";
import styled from "styled-components";

const Container = styled.div`
  background-color: white;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  padding: 0.5rem 1rem 0 1rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
`;

const FiltersContainer = ({
  children,
  handleSearch,
  handlerClear,
  disabledSearch,
  disabledClear,
}) => {
  return (
    <Container>
      {children}
      <Button
        variant={"primary"}
        icon={<CiSearch />}
        size="sm"
        onClick={handleSearch}
        disabled={disabledSearch}
      />
      <Button
        variant={"danger"}
        icon={<GiBroom />}
        size="sm"
        onClick={handlerClear}
        disabled={disabledClear}
      />
    </Container>
  );
};

export default FiltersContainer;
