const numberFormat = (numero, digitos = 2) => {
  let parsedNumber = parseFloat(numero) || 0;
  return parsedNumber.toLocaleString('en-US', { minimumFractionDigits: digitos, maximumFractionDigits: digitos });
};

const NumberRound = ({digits, children}) => {
  let d = digits ? digits : 2
  let parsedNumber = parseFloat(children) || 0;
  return parsedNumber.toLocaleString('en-US', {minimumFractionDigits: d, maximumFractionDigits: d});
}

export const formatNumber = (number, symbol, digitos = 2) => {
  let parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    parsedNumber = 0;
  }

  if (symbol == null) {
    digitos = 0;
  }

  const formattedNumber = parsedNumber.toLocaleString('en-US', {
    minimumFractionDigits: digitos,
    maximumFractionDigits: digitos
  });

  if (symbol == null) {
    return formattedNumber;
  }

  return `${symbol} ${formattedNumber}`;
};

export {NumberRound,numberFormat}
