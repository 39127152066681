import React, {useEffect, useState} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from '../../../../hooks/useFetch2';

const FondoSelect = React.forwardRef((props, ref) => {
  const {period, onChange, ...restProps} = props;
  const {data, loading} = useFetch2(`${Apis.CAJA}/fondos/by-period?period=${period}`, []);
  const [newData, setNewData] = useState([{id: '', name: 'Seleccione'}]);

  useEffect(() => {
    if (data) {
      setNewData([{id: null, name: 'EXTERNO'}, ...data]);
    }
  }, [data]);

  return (
    <>
      {!loading ?
        <SelectV2 ref={ref} options={newData} onChange={onChange} {...restProps} />
        :
        <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default FondoSelect
