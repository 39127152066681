import React, {useEffect, useMemo, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title, Tooltip} from "@wargostec/react-components";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {Controller, useForm} from "react-hook-form";
import moment from "moment/moment";
import {successToast} from "../../../../components/layout/ToastStyles";
import NoDataTable from "../../../common/components/NoDataTable";
import Loader1 from "../../../common/components/Loader1";
import Card from "../../../../components/forms/Card";
import MachineInformation from "./MachineInformation";
import {useHistory, useLocation} from "react-router";
import Chart from "react-apexcharts";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import tgmApi from "../../../../apis/tgmApi";
import useLoadingToastr from "../../../../hooks/useLoadingToastr";
import {BsFilter} from "react-icons/bs";
import DateFilter from "../../../../components/datefilter/DateFilter";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import {FlexLayout} from "../../../../components/flex/Flex";
import SegmentCircleV2 from "../../../crm/pages/clientes/customers/SegmentCircleV2";
import {getGenericLink, serializeDownload} from "../../../../util";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import {getSnapText} from "../../../../components/util/SnapText";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import {DateTime} from "luxon";
import MachineSelectV2 from "../../../common/components/selects/MachineSelectV2";
import Container from "react-bootstrap/Container";
import PageContainer from "../../../../components/layout/PageContainer";

const HistorialDeContadores = () => {

  const [slideOpen, setSlideOpen] = useState(false)
  const initialDate = moment(new Date()).format('YYYY-MM-DD')
  const history = useHistory();
  const location = useLocation();
  let defaultParams = {ini: initialDate, fin: initialDate}

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')
  let confId = query.get('confId')

  if (iniQueryParams !== null && iniQueryParams !== undefined) {
    defaultParams.ini = iniQueryParams
  } else {
    defaultParams.ini = initialDate
  }

  if (finQueryParams !== null && finQueryParams !== undefined) {
    defaultParams.fin = finQueryParams
  } else {
    defaultParams.fin = initialDate
  }

  if (confId !== null && confId !== undefined) {
    defaultParams.confId = confId
  }

  const [params, setParams] = useState(defaultParams)

  const {data, loading, error, fetchData} = useFetch2(Apis.TGM + `/meter-snap/by-dates`, [], params, false)

  const [enabled, setEnabled] = useState(0)

  const {
    register,
    handleSubmit,
    control,
  } = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  const fechasDefault = {ini: defaultParams.ini, fin: defaultParams.fin}
  const [fechas, setFechas] = useState(fechasDefault)

  const changeRoute = (params) => {
    history.replace(`/tgm/meter-snap?${serializeDownload(params)}`)
  }

  useEffect(() => {
    const previousPath = sessionStorage.getItem('previousPath');
    if (!previousPath) {
      sessionStorage.setItem('previousPath', location.state?.from || document.referrer);
    }
  }, [location]);

  const handleBackClick = () => {
    const previousPath = sessionStorage.getItem('previousPath');
    if (previousPath) {
      history.push(previousPath);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (params.confId) {
      changeRoute(params)
      fetchData()
    }
  }, [params])

  const lockAndUnlock = async (id, locked) => {
    if (!locked) {
      await tgmApi.meterSnapEnable(id)
        .then(() => {
            setEnabled(enabled + 1)
            successToast("Registro habilitada correctamente", 2000)
            //fetchData()
          }
        )
    } else {
      await tgmApi.meterSnapDisable(id)
        .then(() => {
            setEnabled(enabled + 1)
            successToast("Registro deshabilitada correctamente", 2000)
            //fetchData()
          }
        )
    }
  }

  const dataMeters = {
    options: {
      chart: {
        id: "line-apostado",
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: data.data?.filter(item => item.wenabled).map(item => moment(item.fechaHora).format('HH:mm')),
        tickAmount: 24,
      },
      stroke: {
        curve: 'smooth',
      },
    },
    series: [
      {
        name: "Coin In",
        data: data.data?.filter(item => item.wenabled).map(item => item.ci),
      },
      {
        name: "Coin Out",
        data: data.data?.filter(item => item.wenabled).map(item => item.co),
      },
      {
        name: "CC",
        data: data.data?.filter(item => item.wenabled).map(item => item.cc),
      },
      {
        name: "Bill",
        data: data.data?.filter(item => item.wenabled).map(item => item.bi),
      },
    ],
  };

  const getColor = (value) => {
    if (value > 0) {
      return 'green'
    } else {
      return 'red'
    }
  }

  const meterAnalyze = async () => {
    const params = {ini: iniQueryParams, fin: finQueryParams}
    await tgmApi.meterSnapAnalize(params)
    successToast('Produccion recalculada')
    fetchData()
  }

  const [executeAnalyze, loadingAnalyze] = useLoadingToastr(meterAnalyze, "Error al analizar")

  const addCereo = async (meterSnapId) => {
    await tgmApi.addCereo(meterSnapId)
    successToast('Cereo agregado')
    fetchData()
  }

  const [executeAddCereo, loadingCereo] = useLoadingToastr(addCereo, "Error al generar cereo")

  const executeSnap = id => axios.post(Apis.TGM + '/meter-snap/' + id + '/use-as-close')
  const cloneSnap = id => axios.post(Apis.TGM + '/meter-snap/' + id + '/clone')

  const {
    wrapper: wrapper,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea este contador como el del cierre?',
    action: executeSnap,
    onRefresh: fetchData
  })

  const {
    wrapper: cloneWrapper,
    openDeleteModal : openCloneModal
  } = useDeleteModal({
    text: '¿Desea clonar esta foto de contadores?',
    action: cloneSnap,
    onRefresh: fetchData
  })

  const renderStatusCell = (item) => {
    return <SegmentCircleV2 color={getGenericLink(item.connected)}/>;
  };

  const renderActionsCell = (item, openDeleteModal, openCloneModal, lockAndUnlock) => {
    return (
      <FlexLayout gap='4px' alignItems='center'>
        <Button size={'s'} onClick={() => openCloneModal(item.id)}>
          Clonar
        </Button>
        <Tooltip text="Contador de cierre" direction={'top'}>
          <Button size={'s'} onClick={() => openDeleteModal(item.id)}>
            Cierre
          </Button>
        </Tooltip>
        {
          (item.incCi < 0 && !item.wbreak) &&
          <Button size={'s'}
                  onClick={() => executeAddCereo(item.id)}
                  disabled={loadingCereo}>
            Cereo
          </Button>
        }
        <SwitchToggle
          defaultChecked={item.wenabled}
          id={"enabled" + item.id}
          name={"enabled"}
          label={"Hab"}
          onClick={() => lockAndUnlock(item.id, item.wenabled)}
        />
      </FlexLayout>
    );
  };

  const columns = useMemo(
    () => [
      {
        header: 'Estado',
        accessorKey: 'connected',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ({row}) => {
          const item = row.original;
          return renderStatusCell(item);
        },
      },
      {
        header: 'Fecha',
        id: 'fechaHora',
        accessorFn: row => row.fechaHora ? DateTime.fromISO(row.fechaHora).toFormat("dd/MM HH:mm") : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fuente',
        id: 'sourceId',
        accessorFn: row => row.sourceId ? getSnapText(row.sourceId) : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incCi',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incCi;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'Coin In',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incCo',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incCo;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'Coin out',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incCc',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incCc;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'C. Credit',
        accessorKey: 'cc',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incBi',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incBi;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'Billetes',
        accessorKey: 'bi',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incTi',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incTi;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'TicketIn',
        accessorKey: 'ticketIn',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incTo',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incTo;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'TicketOut',
        accessorKey: 'ticketOut',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Handpay',
        accessorKey: 'hp',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incTiq',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incTiq;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'TIQ',
        accessorKey: 'tiq',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inc',
        accessorKey: 'incToq',
        meta: {
          headerClassName: 'text-right font-14',
          columnClassName: 'text-right font-14',
        },
        cell: ({row}) => {
          const value = row.original.incToq;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        },
      },
      {
        header: 'TOQ',
        accessorKey: 'toq',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Diff Ti',
        accessorKey: 'diffTi',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: ({row}) => {
          const value = row.original.diffTi;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        }
      },
      {
        header: 'Diff To',
        accessorKey: 'diffTo',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: ({row}) => {
          const value = row.original.diffTo;
          return value ? (
            <span style={{color: getColor(value)}}>{value}</span>
          ) : '';
        }
      },
      {
        header: 'Acciones',
        id: 'acciones',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: ({row}) => {
          const item = row.original;
          return renderActionsCell(item, openDeleteModal, openCloneModal, lockAndUnlock);
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <Container fluid>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Maquina</label>
            <Controller
              name="confId"
              control={control}
              render={({field}) => <MachineSelectV2 {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Fecha de Inicio</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>
          <FormGroup>
            <label>Fecha de Fin</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <PageBackLabel text='Regresar' onClick={handleBackClick}/>
        <Title text="Historial de contadores" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={() => setSlideOpen(!slideOpen)}>
            <BsFilter/> Filtrar
          </Button>
          <Button variant={'primary'} title={'Analizar'} onClick={executeAnalyze} disabled={loadingAnalyze}/>
        </ButtonBox>
        {params.confId &&
          <>
            <Card padding style={{background: '#0A0A0A'}}>
              <DateFilter fechas={fechas} setFechas={setFechas}/>
            </Card>
            <MachineInformation data={data.machine}/>
          </>
        }
        {loading ? <Loader1/> :
          (
            data && data.data?.length > 0 ?
              <Card padding>
                <Chart
                  options={dataMeters.options}
                  series={dataMeters.series}
                  type="area"
                  width="100%"
                  height={350}
                />
              </Card>
              :
              <Card padding>
                <NoDataTable text={params.confId ? "No hay datos" : "Filtrar por una maquina"}/>
              </Card>
          )

        }
        {params.confId &&
          <AsyncBlock error={error} loading={loading}>
            <ReactTable
              columns={columns}
              data={(data?.data) || []}
              noData='No existen resultados para el filtro seleccionado'
              pageSize={200}
              customSearch
              styleRowClass={(row) => {
                if (row.original.closeDay) {
                  return "table-warning"
                } else if (row.original.wbreak) {
                  return "table-danger"
                } else {
                  return ''
                }
              }}
            />
          </AsyncBlock>
        }
      </PageSection>
      <DeleteGenericModal {...wrapper} />
      <DeleteGenericModal {...cloneWrapper} />
    </Container>
  )
}

export default HistorialDeContadores
