import React, {useContext} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory} from "react-router";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import MachineSelectV2 from "../../../common/components/selects/MachineSelectV2";

const CBillOp = () => {
  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const fondoSelected = localStorage.getItem('fondoSelected');
  const fondoId = fondoSelected ? JSON.parse(fondoSelected).id : null;
  const fondoName = fondoSelected ? JSON.parse(fondoSelected).name : null;
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit, setValue, control} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.fondoId = fondoId
    params.salaId = salaId
    axios.post(Apis.CAJA + '/operation-billetero', params)
      .then(res => {
        successToast()
        clear()
      }).catch(err => {
        console.log(err)
        errorToast()
      })
  }

  const clear = () => {
    setValue('confId', "")
    setValue('amount', "")
  }

  return (
        <FormBlock>
          <FormGroup>
            <label>Fondo</label>
            <Input value={fondoName} disabled/>
          </FormGroup>
          <FormGroup>
            <label>Máquina</label>
            <Controller
              name="confId"
              control={control}
              render={({field}) => <MachineSelectV2 {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")} />
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)} />
          </ButtonBox>
        </FormBlock>
  )
}

export default CBillOp;
