import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import PageSection from "../../../../../components/layout/PageSection";
import CardOverview, {CardOverviewContainer} from "../../../../../components/layout/CardOverview";
import moment from "moment";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {HiOutlineClock} from "react-icons/hi";
import {Title} from "@wargostec/react-components";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import Card from "../../../../../components/forms/Card";
import {CardTitle} from "../../../../crm/pages/coupons/PrinterDetail";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import {useForm} from "react-hook-form";
import {useHistory, useParams} from "react-router";
import useFilasMesa from "../../../../../hooks/useFilasMesa";
import styled from "styled-components";
import useFetch2 from "../../../../../hooks/useFetch2";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import {getStatusName} from "../MovimientosFondo";
import {FlexLayout} from "../../../../../components/flex/Flex";
import Moment from "react-moment";
import MoneyColoredRound from "../../../../../components/util/MoneyColored";
import {Tab, Tabs} from "../../../../../components/tabs/Tabs";
import {formatNumber} from "../../../../../components/util/NumberUtil";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {SiteContext} from '../../../../common/context/SiteContext';
import Button from "../../../../../components/forms/Button";

const TextLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 8px;
`

const StatusTag = styled.span`
    background-color: ${(props) => (props.isClosed ? '#4caf50' : '#fce91c')};
    color: black;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 15px;
    border-radius: 12px;
    margin-left: 10px;
    margin-top: -25px;
`;

const Subtitle = styled.div`
    font-size: 17px; 
    color: #666;
    text-transform: uppercase;
    font-weight: normal;
    margin-top: -25px;
`;

const FondoClosed = () => {

  const {id} = useParams()
  const {salaId} = useContext(SiteContext);
  const history = useHistory()
  const [preArqueo, setPreArqueo] = useState()
  const [enable, setEnable] = useState(false);
  const {rows, setRows, total} = useFilasMesa("CAJA")

  const {
    loading,
    data,
    error
  } = useFetch2(Apis.CAJA + '/transfer/report', [], {fondoId: id});

  // https://api5.wargos.cloud/xcaja/operation-ticket-venta/report/by-fondo?validOnly=false&fondoId=1681

  const {
    loading: loadingTicketVenta,
    data: dataTicketVenta,
    error: errorTicketVenta
  } = useFetch2(Apis.CAJA + '/operation-ticket-venta/report/by-fondo', [], {fondoId: id, validOnly: false});

  const {
    loading: loadingBilletero,
    data: dataBilletero,
    error: errorBilletero
  } = useFetch2(Apis.CAJA + '/operation-billetero/report/by-fondo', [], {fondoId: id, salaId: salaId});

  const {
    loading: loadingCanjeTicket,
    data: dataCanjeTicket,
    error: errorCanjeTicket
  } = useFetch2(Apis.CAJA + '/operation-ticket/report/by-fondo', [], {fondoId: id});

  const {
    loading: loadingHandPay,
    data: dataHandPay,
    error: errorHandPay
  } = useFetch2(Apis.CAJA + '/operation-handpay/report/by-fondo', [], {fondoId: id});

  const {
    loading: loadingCardPay,
    data: dataCardPay,
    error: errorCardPay
  } = useFetch2(Apis.CAJA + '/operation-cardpay/report/by-fondo', [], {fondoId: id});

  const {
    loading: loadingRefill,
    data: dataRefill,
    error: errorRefill
  } = useFetch2(Apis.CAJA + '/refill/report/by-fondo', [], {fondoId: id, salaId: salaId});

  const {
    loading: loadingOther,
    data: dataOther,
    error: errorOther
  } = useFetch2(Apis.CAJA + '/operation-other/report/by-fondo', [], {fondoId: id, salaId: salaId});

  const {
    loading: loadingExchangeRate,
    data: dataExchangeRate,
    error: errorExchangeRate
  } = useFetch2(Apis.CAJA + '/operation-exchange-rate', [], {fondoId: id});

  const {getValues} = useForm()

  const handleSaveArqueo = async () => {
    const body = {
      fondoId: id,
      foundedAmount: preArqueo?.diff || 0,
      targetFondoId: null
    }
    try {
      await axios.post(Apis.CAJA + `/fondos/${id}/arqueo-and-close`, body)
      history.push('/caja/fondo-list')
      successToast()
    } catch (error) {
      errorToast({
        title: 'Error al generar el cierre, intente nuevamente en unos minutos',
        variant: 'danger',
      })
    }
  }

  const getData = async () => {
    let body = {
      "foundedAmount": 0
    }
    try {
      const {data} = await axios.get(Apis.CAJA + `/fondos/${id}/full-summary`, body)
      if (data) {
        setPreArqueo(data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const FondoDeOrigen = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.amount > 0 ?
          <div>{props.row.original.relatedFondoName}</div> :
          <div>{props.row.original.fondoName}</div>
      }
    </FlexLayout>
  }</>, [])

  const FondoDeDestino = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.amount > 0 ?
          <div>{props.row.original.fondoName}</div> :
          <div>{props.row.original.relatedFondoName}</div>
      }
    </FlexLayout>
  }</>)

  const NumberComponent = useCallback(props =>
    <MoneyColoredRound
      currencySymbol={data.currencySymbol}>{props.renderValue()}
    </MoneyColoredRound>, [data]);

  const transferAmount = useCallback(props =>
    <MoneyColoredRound
      currencySymbol={data.currencySymbol}>{props.row.original.sourceFondoId == id ? -props.renderValue() : props.renderValue()}
    </MoneyColoredRound>, [data]);

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fondo de origen',
        accessorKey: 'sourceFondo',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fondo destino',
        accessorKey: 'targetFondo',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: transferAmount
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Estado',
        id: 'status',
        accessorFn: row => getStatusName(row.status),
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
    ]
    , []
  )

  const columnsHandpay = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.gameName ? props.row.original.gameName : '--'}</>
      },
      {
        header: 'Documento',
        accessorKey: 'customerDocument',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.customerDocument ? props.row.original.customerDocument : '--'}</>
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.customerName != '  ' ? props.row.original.customerName : '--'}</>
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ]
    , []
  )

  const columnsTicketVenta = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Ticket',
        accessorKey: 'validNumber',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Emitido en',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha de emisión',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Canjeado en',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.machine ? props.row.original.machine : 'CAJA'}</>
      },
      {
        header: 'Fecha de canje',
        accessorKey: 'redeemedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ]
    , [])

  const columnsTicketCanje = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Ticket',
        accessorKey: 'validNumber',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Emitido en',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.machine ? props.row.original.machine : 'CAJA'}</>
      },
      {
        header: 'Fecha de emisión',
        accessorKey: 'emittedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Canjeado en',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha de canje',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ]
    , [])

  const columnsCardPay = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Banco',
        accessorKey: 'bankName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Tipo',
        accessorKey: 'cardType',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Últimos 4 dígitos',
        accessorKey: 'cardNumber4',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.cardNumber4 ? '****' + props.row.original.cardNumber4 : '--'}</>
      },
      {
        header: 'Cliente',
        accessorKey: 'customerId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.customerId ? props.row.original.customerName : '--'}</>
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ]
    , []
  )

  const columnsRefill = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Nombre de juego',
        accessorKey: 'gameName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.gameName ? props.row.original.gameName : '--'}</>
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Fichas por cobrar',
        accessorKey: 'fichasPorCobrar',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ]
    , [])

  const columnsOther = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Categoria',
        accessorKey: 'categoryName'
      },
      {
        header: 'Descripción',
        accessorKey: 'description',
        meta: {
          columnClassName: 'text-xs max-w-[200px]',
        },
        cell: props => <>{props.row.original.description !== '' ? props.row.original.description : '--'}</>
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: NumberComponent
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      }
    ],
    []);

  const columnsExchangeRate = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'De',
        accessorKey: 'enteredAmount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: props => <>{props.row.original.enteredAmount ? formatNumber(props.row.original.enteredAmount, '$') : '--'}</>
      },
      {
        header: 'Cambio',
        accessorKey: 'rate',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'A',
        accessorKey: 'amountDelivered',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: props => <>{props.row.original.rate ? formatNumber(props.row.original.rate, 'S/') : '--'}</>
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ],
    []);

  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()}/>

        <div style={{display: 'flex',alignItems: 'center', marginBottom: '5px'}}>
          <Title text={preArqueo?.fondoName || 'Fondo'} type={'page'}/>
          <StatusTag isClosed={preArqueo?.fondoStatus == 1}>
            {preArqueo?.fondoStatus == 1 ? ' Abierto' : ' Cerrado'}
          </StatusTag>
        </div>
        <div style={{paddingBottom: '15px'}}>
          <Subtitle>
            {preArqueo?.fondoType == 1 ? 'BOVEDA' : 'CAJA'}
          </Subtitle>
        </div>


        <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
          <CardOverview
            title={preArqueo?.fondoCreatedAt ? moment(preArqueo.fondoCreatedAt).format("YYYY-MM-DD hh:mm") : '---'}
            subtitle='Apertura de fondo'
            icon={<HiOutlineClock size={24} />}
          />

          <CardOverview
            title={(preArqueo?.fondoStatus == 2 && preArqueo?.fondoClosedAt) ? moment(preArqueo.fondoClosedAt).format("YYYY-MM-DD hh:mm") : '---'}
            subtitle='Cierre de fondo'
            icon={<HiOutlineBanknotes size={24} />}
          />
        </CardOverviewContainer>
        <FlexLayout gap='16px'>
          <Card padding style={{flex: 1, width: '100%'}}>
            <span style={{fontWeight: 800, fontSize: '20px'}}>Resumen</span>
            <FlexLayout column style={{padding: '16px 16px 0', width: '100%'}}>
              <TextLabelContainer>
                <label>Monto inicial</label>
                <strong
                  style={{textAlign: 'right'}}>{preArqueo?.transferIniAmount != null ? <MoneyColoredRound currencySymbol={'S/ '}>{preArqueo?.transferIniAmount}</MoneyColoredRound> : '---'}</strong>
              </TextLabelContainer>
              <TextLabelContainer>
                <label>Transacciones</label>
                <strong
                  style={{textAlign: 'right'}}>{preArqueo?.transferNoIniNoFin != null ? <MoneyColoredRound currencySymbol={'S/ '}>{preArqueo?.transferNoIniNoFin}</MoneyColoredRound> : '---'}</strong>
              </TextLabelContainer>
              <TextLabelContainer>
                <label>Operaciones</label>
                <strong
                    style={{textAlign: 'right'}}>{preArqueo?.totalOperationNoTransfer != null ? <MoneyColoredRound currencySymbol={'S/ '}>{preArqueo?.totalOperationNoTransfer}</MoneyColoredRound> : '---'}</strong>
              </TextLabelContainer>
              <TextLabelContainer>
                <label>Monto final</label>
                <strong
                  style={{textAlign: 'right'}}>{preArqueo?.transferFinAmount != null ? <MoneyColoredRound currencySymbol={'S/ '}>{-preArqueo?.transferFinAmount}</MoneyColoredRound> : '---'}</strong>
              </TextLabelContainer>
              <hr style={{width: '100%'}}/>
              <TextLabelContainer>
                <label>Faltante / Sobrante</label>
                <strong style={{textAlign: 'right'}}>{preArqueo?.diff != null ?
                    <MoneyColoredRound currencySymbol={'S/ '}>{preArqueo?.diff}</MoneyColoredRound> : '---'}
                </strong>
              </TextLabelContainer>
            </FlexLayout>
            <div style={{padding: '16px', display: 'flex', justifyContent: 'flex-end'}}>
              {
                preArqueo?.fondoType == 1 &&
                <Button variant='primary' onClick={() => handleSaveArqueo()}>Cerrar</Button>
              }
            </div>
          </Card>

          <Card padding style={{flex: 1, width: '100%'}}>
            <span style={{fontWeight: 800, fontSize: '20px'}}>Operaciones</span>
            <CardTitle className="font-bold"></CardTitle>
            <FlexLayout column style={{padding: '16px 16px 0', width: '100%'}}>

              <span style={{fontWeight: 700, fontSize: '16px'}}>Entradas</span>
              <FlexLayout column gap='4px' style={{width: '100%'}}>
                <TextLabelContainer>
                  <label className="flex-1">Total tickets venta</label>
                  <strong
                    style={{textAlign: 'right'}}>{preArqueo?.ticketVenta != null ? <MoneyColoredRound currencySymbol={'S/ '}>{preArqueo?.ticketVenta}</MoneyColoredRound> : '---'}</strong>
                </TextLabelContainer>
                <TextLabelContainer>
                  <label className="flex-1">Total billetero</label>
                  <strong
                    style={{textAlign: 'right'}}>{preArqueo?.bill != null ? <MoneyColoredRound currencySymbol={'S/ '}>{preArqueo?.bill}</MoneyColoredRound> : '---'}</strong>
                </TextLabelContainer>
              </FlexLayout>

              <span style={{fontWeight: 700, fontSize: '16px'}}>Salidas</span>
              <FlexLayout column gap='4px' style={{width: '100%'}}>
                <TextLabelContainer>
                  <label className="flex-1">Total tickets canje</label>
                  <strong
                    style={{textAlign: 'right'}}>{preArqueo?.ticket != null ? <MoneyColoredRound currencySymbol={'S/ '}>{-preArqueo?.ticket}</MoneyColoredRound> : '---'}</strong>
                </TextLabelContainer>
                <TextLabelContainer>
                  <label className="flex-1">Total pagos manuales</label>
                  <strong
                    style={{textAlign: 'right'}}>{preArqueo?.handpay != null ? <MoneyColoredRound currencySymbol={'S/ '}>{-preArqueo?.handpay}</MoneyColoredRound> : '---'}</strong>
                </TextLabelContainer>
                <TextLabelContainer>
                  <label className="flex-1">Total tarjetas</label>
                  <strong
                    style={{textAlign: 'right'}}>{preArqueo?.cardpay != null ? <MoneyColoredRound currencySymbol={'S/ '}>{-preArqueo?.cardpay}</MoneyColoredRound> : '---'}</strong>
                </TextLabelContainer>
                <TextLabelContainer>
                  <label className="flex-1">Total otros</label>
                  <strong
                    style={{textAlign: 'right'}}>{preArqueo?.other != null ? <MoneyColoredRound currencySymbol={'S/ '}>{-preArqueo?.other}</MoneyColoredRound> : '---'}</strong>
                </TextLabelContainer>
              </FlexLayout>

              <span style={{fontWeight: 700, fontSize: '16px'}}>Operaciones T.C.</span>
              <FlexLayout column gap='4px' style={{width: '100%'}}>
                  <TextLabelContainer>
                      <label className="flex-1">Dolares</label>
                      <strong
                          style={{textAlign: 'right'}}>{preArqueo?.currencyArqueo[0] !== null ? formatNumber(preArqueo?.currencyArqueo[0]?.amount, '$') + ` (S/ ${preArqueo?.currencyArqueo[0]?.amountDeliveredTotal ? preArqueo?.currencyArqueo[0]?.amountDeliveredTotal.toFixed(2) : '0.00'})` : '--'}</strong>
                  </TextLabelContainer>
              </FlexLayout>
            </FlexLayout>
          </Card>
        </FlexLayout>

        <Tabs>
          <Tab title="Transferencias">
            <AsyncBlock loading={loading} error={error}>
              <ReactTable
                showColumnFilters={false}
                columns={columns}
                data={(data && data.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Venta de tickets">
            <AsyncBlock loading={loadingTicketVenta} error={errorTicketVenta}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsTicketVenta}
                data={(dataTicketVenta && dataTicketVenta.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Canje de tickets">
            <AsyncBlock loading={loadingCanjeTicket} error={errorCanjeTicket}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsTicketCanje}
                data={(dataCanjeTicket && dataCanjeTicket.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Pagos manuales">
            <AsyncBlock loading={loadingHandPay} error={error}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsHandpay}
                data={(dataHandPay && dataHandPay.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Tarjetas">
            <AsyncBlock loading={loadingCardPay} error={errorCardPay}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsCardPay}
                data={(dataCardPay && dataCardPay.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Rellenos">
            <AsyncBlock loading={loadingRefill} error={errorRefill}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsRefill}
                data={(dataRefill && dataRefill.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Gastos">
            <AsyncBlock loading={loadingOther} error={errorOther}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsOther}
                data={(dataOther && dataOther.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Tipo de cambio">
            <AsyncBlock loading={loadingExchangeRate} error={errorExchangeRate}>
              <ReactTable
                showColumnFilters={false}
                columns={columnsExchangeRate}
                data={(dataExchangeRate && dataExchangeRate.data) || []}
                noData='No existen resultados'
                pageSize={15}
              />
            </AsyncBlock>
          </Tab>
        </Tabs>


      </PageSection>

    </PageContainerFull>
  );
};

export default FondoClosed;
