import React, {useCallback, useEffect, useMemo, useState} from "react";
import FormGroup from "../../../../components/forms/FormGroup";
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Button from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {FlexLayout} from "../../../../components/flex/Flex";
import {NumberRound} from "../../../../components/util/NumberUtil";
import useFetch2 from "../../../../hooks/useFetch2";
import moment from "moment";
import {useHistory} from "react-router-dom";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaCube} from "react-icons/fa";
import {BsCashCoin} from "react-icons/bs";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import ReactTable from "../../../../components/util/ReactTable";
import {PageSection, Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";

const Billetera = () => {

  const [slideOpen, setSlideOpen] = useState(false)

  const history = useHistory();

  const initialDate = moment(new Date()).format('YYYY-MM-DD')
  let defaultParams = {ini: initialDate, fin: initialDate}
  const [params, setParams] = useState(defaultParams)
  const {data, loading, error, fetchData} = useFetch2(Apis.TGM + '/deltas/billetera', [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')

  if (iniQueryParams === null) {
    iniQueryParams = initialDate
  }

  if (finQueryParams === null) {
    finQueryParams = initialDate
  }

  const fechasDefault = {ini: iniQueryParams, fin: finQueryParams}
  const [fechas, setFechas] = useState(fechasDefault)

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    setParams({...params, ...fechas})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])

  const defaultValue = useCallback(props => 'S/ ' + props.renderValue(), []);
  const actionsValue = useCallback(props =>
      <FlexLayout justifyContent='center'>
        <Button variant={'light'} size='xs'
                onClick={() => history.push(`/tgm/meter-snap?ini=${moment(new Date()).format('YYYY-MM-DD')}&fin=${moment(new Date()).format('YYYY-MM-DD')}&confId=${props.renderValue()}`)}>
          Detalles
        </Button>
      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const [horaIni, setHoraIni] = useState('08:00');
  const [horaFin, setHoraFin] = useState('08:00');

  const handleInputChange1 = (e) => {
    setHoraIni(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setHoraFin(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Modelo',
        accessorKey: 'machineGroupName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
        headerClassName: 'cpointer',
      },
      {
        header: 'Denom.',
        accessorKey: 'den',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Inicial',
        accessorKey: 'billIni',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Ahora',
        accessorKey: 'billFin',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Bill',
        accessorKey: 'bi',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Acciones',
        accessorKey: 'confId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableSorting: false, // para deshabilitar el sort
        enableGlobalFilter: false, // para deshabilitar el filter
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Billetero" type={'page'}/>
        <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
          <div style={{padding: '16px'}}>
            <FormGroup>
              <label>Grupo de Máquinas</label>
              <SelectV2 options={[
                {id: "", name: "Todas"},
                {id: "1", name: "ARISSTOCRAT"},
                {id: "2", name: "IGT"},
                {id: "3", name: "NOVOMATIC"},
                {id: "4", name: "EGT"},
                {id: "5", name: "MERKUR"},
                {id: "6", name: "KONAMI"},
                {id: "7", name: "RULETA"},
                {id: "7", name: "DERBY ANDAMIRO"},
              ]}>
              </SelectV2>
            </FormGroup>
            <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
          </div>
        </Slidebar>

        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas}/>
        </Card>

        <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
          <CardOverview
            title={data?.data && data?.data.length}
            subtitle='Máquinas'
            icon={<FaCube size={20}/>}
          />

          <CardOverview
            title={data?.billeteraTot !== 'NaN' ?
              <span>
                S/ <NumberRound>{data?.billeteraTot}</NumberRound>
              </span> : '-'
            }
            subtitle='Total'
            icon={<BsCashCoin size={20}/>}
          />
        </CardOverviewContainer>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data?.data || []}
            customSearch
            filterOnclick={() => setSlideOpen(!slideOpen)}
            loading={loading}
            pageSize={100}
            //sortArray={[{id: 'machine', asc: false}]}
            noData='No existen resultados para el filtro seleccionado'
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>

  )
}

export default Billetera;
