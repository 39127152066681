import React, {useEffect, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input, {SelectV2} from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import CardTypeSelect from "../../../common/components/selects/CardTypeSelect";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import {Tab, Tabs} from "../../../../components/tabs/Tabs";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import RefillOp from "./RefillOp";
import OtherPayOp from "./OtherPayOp";
import TransferOp from "./TransferOp";
import CBillOp from "./CBillOp";
import FichasMesas from "./FichasMesas";
import {BodyDialog, StyledBackdrop, StyledDialog} from "../../../crm/pages/clientes/LayoutInfoMachine";
import useFetch2 from "../../../../hooks/useFetch2";
import {successToast} from "../../../../components/layout/ToastStyles";
import BankSelect from "../../../common/components/selects/BankSelect";
import {useHistory} from "react-router";

const CardPayOp = () => {

  let defaultParams = {enabled: true};
  const fondoSelected = localStorage.getItem("fondoSelected");
  const fondoId = fondoSelected ? JSON.parse(fondoSelected).id : null;
  const fondoName = fondoSelected ? JSON.parse(fondoSelected).name : null;
  const {register, control, handleSubmit, reset} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.fondoId = fondoId;
    axios.post(Apis.CAJA + "/operation-cardpay", params).then(res => {
      successToast("Se ha guardado correctamente");
      reset({amount: "", customerId: "", cardType: "", cardNumber4: ""});
    }).catch(err => {

    });
  };

  return (
    <FormBlock>
      <FormGroup>
        <label>Fondo</label>
        <Input value={fondoName} disabled />
      </FormGroup>
      <FormGroup>
        <label>Monto</label>
        <Input {...register("amount")} />
      </FormGroup>
      <FormGroup>
        <label>Cliente</label>
        <Controller name="customerId" control={control}
                    render={({field}) => <CustomerSearchInputV2 {...field} />} />
      </FormGroup>
      <FormGroup>
        <label>Tipo de tarjeta</label>
        <CardTypeSelect {...register("cardType")} />
      </FormGroup>
      <FormGroup>
        <label>Banco</label>
        <Controller
          name="bankId"
          control={control}
          render={({field}) => <BankSelect {...field} />}
        />
      </FormGroup>
      <FormGroup>
        <label>Ultimos 4 dígitos</label>
        <Input {...register("cardNumber4")} />
      </FormGroup>
      <ButtonBox>
        <Button title={"Guardar"} variant={"primary"} onClick={handleSubmit(onCreate)} />
      </ButtonBox>
    </FormBlock>
  );
};

const Operations = () => {

  const history = useHistory()
  const {data, loading, fetchData} = useFetch2(`${Apis.CAJA}/fondos?fundType=1&active=true`, []);
  const [selectedFondo, setSelectedFondo] = useState(null);
  const [newData, setNewData] = useState([{id: "", name: "Seleccione"}]);

  useEffect(() => {
    if (data) {
      setNewData([{id: "", name: "Seleccione"}, ...data]);
    }
  }, [data]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const fondoSelected = localStorage.getItem("fondoSelected");

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedOption = data.find(option => option.id === parseInt(selectedId));
    setSelectedFondo(selectedOption);
  };

  const createPosition = (value) => {
    localStorage.setItem("fondoSelected", JSON.stringify(value));
    handleCloseDialog();
  };

  useEffect(() => {
    if (!fondoSelected) {
      setDialogOpen(true);
    }
  }, []);

  const createOrSelectFondo = () => {
    if (data && data.length > 0) {
      fetchData()
      setDialogOpen(true);
    } else {
      history.push("/caja/fondo-list");
    }
  }

  useEffect(() => {
    if (fondoSelected && data && data.length > 0) {
      const fondo = data.find(fondo => fondo.id === JSON.parse(fondoSelected).id);
      if (!fondo) {
        setDialogOpen(true);
      }
    }
  }, [data, fondoSelected]);

  return (
    <PageContainerFull>
      {dialogOpen && (
        <>
          <StyledBackdrop
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            onClick={selectedFondo ? handleCloseDialog : null}
          />
          <>
            <StyledDialog
              initial={{opacity: 0, y: -20}}
              animate={{opacity: 1, y: 0}}
              exit={{opacity: 0, y: -20}}
              style={{
                position: "fixed",
                top: "30%",
                left: "40%"
              }}
            >
              <>
                <BodyDialog>
                  <FormGroup>
                    <label>Seleccione un fondo</label>
                    <SelectV2
                      options={data && newData}
                      onChange={handleSelectChange}
                    />
                  </FormGroup>
                  <div style={{marginLeft: "auto"}}>
                    <ButtonBox>
                      <Button title={"Guardar"} size="s" variant={"primary"}
                              onClick={() => createPosition(selectedFondo)} />
                      {
                        data && data.length == 0 &&
                        <Button size="s" variant="dark" onClick={handleCloseDialog}>Cerrar</Button>
                      }
                    </ButtonBox>
                  </div>
                </BodyDialog>
              </>
              <ButtonBox style={{width: "100%", justifyContent: "flex-end", marginBottom: "0px"}}>
              </ButtonBox>
            </StyledDialog>
          </>
        </>
      )}

      <PageSection>
        <div style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem"
        }}>
          <Title text="Operaciones" type={"page"} />
          <Button onClick={createOrSelectFondo} style={{marginTop: "-20px"}} variant={"warning"}>
            {fondoSelected ? JSON.parse(fondoSelected).name : "Crear un fondo"}
          </Button>
        </div>
        <Tabs>
          <Tab title="Transferencias">
            <TransferOp />
          </Tab>
          <Tab title="Gastos">
            <OtherPayOp />
          </Tab>
          <Tab title="Pre billetero">
            <CBillOp />
          </Tab>
          <Tab title="Pagos con tarjetas">
            <CardPayOp />
          </Tab>
          <Tab title="Rellenos">
            <RefillOp />
          </Tab>
          <Tab title="Fichas mesas">
            <FichasMesas />
          </Tab>
        </Tabs>
      </PageSection>
    </PageContainerFull>
  );
};

export default Operations;
