import React, {useCallback, useContext, useEffect, useState} from 'react'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import {Button, FormGroup, PageSection, Title, Tooltip} from '@wargostec/react-components'
import ReactTable from '../../../../components/util/ReactTable';
import SalaIdInput from '../../../common/components/SalaIdInput';
import {DatePickerCustom} from '../../../../components/forms/FormEl';
import {useForm} from 'react-hook-form';
import useToggle from '../../../../hooks/useToggle';
import {SiteContext} from '../../../common/context/SiteContext';
import Slidebar from '../../../common/layout/Slidebar';
import {numberFormat} from '../../../../components/util/NumberUtil';
import {getDateFilter} from "../../../../components/util/CasinoDates";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import DateFilter2 from "../../../../components/datefilter/DateFilter2";
import {FlexLayout} from "../../../../components/flex/Flex";
import {useHistory} from "react-router";
import {AiOutlineAudit} from "react-icons/ai";
import useEditModal from "../../../../modals/useEditModal";
import AuditModal from "./AuditModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import MoneyColored from "../../../../components/util/MoneyColored";
import useLoadingToastr from "../../../../hooks/useLoadingToastr";
import {successToast} from "../../../../components/layout/ToastStyles";
import axios from "axios";
import {FcSynchronize} from "react-icons/fc";
import {FaFilePdf} from "react-icons/fa";
import PointerSpan from "../../../../components/util/PointerSpan";

const AuditVentaDiaria = () => {

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});
  const history = useHistory();

  const date = getDateFilter('month')
  const fechasDefault = {ini: date.ini, fin: date.fin}
  const [fechas, setFechas] = useState(fechasDefault)
  let defaultParams = {salaId: salaId, enabled: true, ini: fechas.ini, fin: fechas.fin}
  const [params, setParams] = useState(defaultParams)

  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/venta-diaria`, [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const productionValue = useCallback(props => {
    const bill = props.row.original.billetero ?? 0
    const ticketVenta = props.row.original.ticketVenta ?? 0
    const ticketRedeem = props.row.original.ticketRedeem ?? 0
    const handpay = props.row.original.handpay ?? 0
    const refill = props.row.original.refill ?? 0
    const total = bill + ticketVenta - ticketRedeem - handpay - refill
    return (
      <MoneyColored currencySymbol={'S/ '}>
        {total}
      </MoneyColored>
    );
  }, [])

  const {wrapper, editAction} = useEditModal({
    editComponent: <AuditModal/>,
    onRefresh: () => fetchData()
  })

  const recalc = async (value) => {
    const params = {
      period: value,
      type: 'AUTOMATIC'
    }
    await axios.post(Apis.CAJA + '/venta-diaria/generate', params)
    successToast("Venta diaria recalculada")
    fetchData();
  }

  const [getRecalc, loadingRecalc] = useLoadingToastr(recalc, "Hubo un error");

  const pdf = (id) => {
    window.location.href = Apis.CAJA + `/venta-diaria/${id}/report.pdf`
  }

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Tooltip text="Descarga PDF" direction={'top'}>
        <Button variant={'light'} size='xs' icon={<FaFilePdf/>} onClick={() => pdf(props.row.original.id)}
                disabled={loadingRecalc}/>
      </Tooltip>
      <Tooltip text="Recalcular" direction={'top'}>
        <Button variant={'light'} size='xs' icon={<FcSynchronize/>}
                onClick={() => getRecalc(props.row.original.fecha)} disabled={loadingRecalc}/>
      </Tooltip>
      <Tooltip text="Cerrar el día" direction={'top'}>
        <Button variant={'light'} size='xs' icon={<AiOutlineAudit/>}
                onClick={() => editAction(props.row.original.fecha)} disabled={loadingRecalc}/>
      </Tooltip>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, [loadingRecalc]);

  const periodValue = useCallback(props => <>{
    <PointerSpan
      onClick={() => history.push(`/caja/fondo-report?ini=${props.row.original.fecha}&fin=${props.row.original.fecha}`)}
      style={{justifyContent: 'center', display: 'flex'}}
    >
      <Tooltip text="Fondos detallados" direction={'top'}>
        {props.renderValue()}
      </Tooltip>
    </PointerSpan>
  }</>, []);

  const columns = [
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      },
      {
        header: 'Periodo',
        accessorKey: 'fecha',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: periodValue
      },
      {
        header: 'Ingresos',
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Billetes',
            id: 'billetesRecaudo',
            accessorFn: row => `${row.billetero !== null ? numberFormat(row.billetero) : '--'}`,
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l',
            }
          },
          {
            header: 'Venta de tickets',
            id: 'ventaTickets',
            accessorFn: row => `${row.ticketVenta !== null ? numberFormat(row.ticketVenta) : '--'}`,
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            }
          },
          {
            header: 'Fichas',
            id: 'fichasRecaudo',
            accessorFn: row => `${row.fichasRecaudo !== null ? numberFormat(row.fichasRecaudo) : '--'}`,
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            }
          },
        ],
      },
      {
        header: 'Premios',
        meta: {
          headerClassName: 'text-center border-l  border-r',
        },
        columns: [
          {
            header: 'Canje tickets',
            id: 'ticketsPagados',
            accessorFn: row => `${row.ticketRedeem !== null ? numberFormat(row.ticketRedeem) : '--'}`,
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l',
            }
          },
          {
            header: 'Pagos manuales',
            id: 'pagosManuales',
            accessorFn: row => `${row.handpay !== null ? numberFormat(row.handpay) : '--'}`,
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            }
          },
          {
            header: 'Rellenos',
            id: 'rellenos',
            accessorFn: row => `${row.refill !== null ? numberFormat(row.refill) : '--'}`,
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            }
          },
        ],
      },
      {
        header: 'Otros',
        meta: {
          headerClassName: 'text-center border-l  border-r',
        },
        columns: [
          {
            header: 'Tarjetas',
            id: 'tarjetas',
            accessorFn: row => `${row.cardpay !== null ? numberFormat(row.cardpay) : '--'}`,
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l',
            }
          },
          {
            header: 'Gastos',
            accessorKey: 'otrosGastos',
            accessorFn: row => `${row.other !== null ? numberFormat(row.other) : '--'}`,
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            }
          },
          {
            header: 'Dólares',
            id: 'dolares',
            accessorFn: row => `${row.dollars !== null ? numberFormat(row.dollars) : '--'}`,
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            }
          },
        ],
      },
      {
        header: 'Producción',
        meta: {
          headerClassName: 'text-right border-l',
          columnClassName: 'text-right border-l',
        },
        accessorKey: 'otros',
        cell: productionValue
      }
    ]

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant='primary' text='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Reporte venta diaria" type={'page'}/>

        <DateFilter2
          modeDate={'month'}
          showDay={false}
          showWeek={false}
          showMonth={true}
          showYear={false}
          toggleSlidebar={toggler}
          fechas={fechas}
          setFechas={setFechas}
        />

        <ReactTable
          columns={columns}
          data={(data && data.data) || []}
          noData='No existen resultados para el filtro seleccionado'
          pageSize={200}
          customSearch
          filterOnclick={toggler}
        />
      </PageSection>
      <GenericModal2 {...wrapper}/>
    </PageContainerFull>
  )
}

export default AuditVentaDiaria
