import React, {useRef, useState} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import Select from "react-select";

const BankSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(`${Apis.CAJA}/bank`)
  const [selectedBank, setSelectedBank] = useState(null);
  const bankSelectRef = useRef(null);

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption);
    props.onChange(selectedOption.value);
  };

  const bankOptions = data?.map(bank => ({
    value: bank.id,
    label: bank.name,
  })) || [];

  return (
    <Select
      options={bankOptions}
      value={selectedBank}
      onChange={handleBankChange}
      placeholder="Seleccione..."
      autoFocus
      ref={bankSelectRef}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width: '100%'
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none'
        })
      }}
    />
  )
})

export default BankSelect;
