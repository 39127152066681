import React, {useCallback, useMemo, useState} from "react";
import {Title} from "@wargostec/react-components";
import {Controller, useForm} from "react-hook-form";
import Chart from "react-apexcharts";
import {useHistory} from "react-router";
import {BsCashCoin} from "react-icons/bs";
import {FaUsers} from "react-icons/fa";
import {PiSmileySad} from "react-icons/pi";
import {MdFiberManualRecord} from "react-icons/md";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import FormGroup from "../../../../components/forms/FormGroup";
import {Apis} from "../../../../util/Apis";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Button from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {FlexLayout} from "../../../../components/flex/Flex";
import Card from "../../../../components/forms/Card";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import DateFilter from "../../../../components/datefilter/DateFilter";
import getCasinoRange from "../../../../components/util/CasinoDates";
import MultiColorProgressBar from "../../../../components/layout/MultiColorProgressBar";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {NumberRound} from "../../../../components/util/NumberUtil";
import CiFormatter from "../../../../components/util/CiFormatter";
import ReactTable from "../../../../components/util/ReactTable";
import DateTimeV2 from "../../../../components/layout/DateTimeV2";
import useToggle from "../../../../hooks/useToggle";
import useGet from "../../../../hooks/useGet";
import {generateEmployeeSessionData, serializeDownload} from "../../../../util";
import {DateTime} from "luxon";

const SesionesXEmpleado = () => {

  const history = useHistory();
  const {isOpen: slideOpen, setIsOpen: setSlideOpen} = useToggle({})

  const {ini, fin} = getCasinoRange()

  let defaultFormParams = {ini: ini, fin: fin}

  const [params, setParams] = useState(defaultFormParams)

  const {register, control, handleSubmit} = useForm({defaultValues: defaultFormParams});

  const [fechas, setFechas] = useState(defaultFormParams)

  const {fetchData, loading, data, error} = useGet({
    url: Apis.PC + '/session/group-by-employee',
    initialValue: [],
    params: params,
  })

  const {data: data2, fetchData: fetchData2, loading: loading2, error: error2} = useGet({
    url: Apis.PC + '/customers/logged-time-distribution',
    initialValue: [],
    params: params,
  })

  const {
    loading: loading3,
    data: data3,
    fetchData: fetchData3,
  } = useGet({
    url: Apis.PC + '/session/group-by-method',
    initialValue: [],
    params: params,
  })

  const download = () => {
    const link = Apis.PC + `/session/group-by-employee.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  const changeRoute = (fechas) => {
    history.push('/crm/sesiones-por-empleado?ini=' + fechas.ini + '&fin=' + fechas.fin)
  }

  const handleChangeDates = async (fechas) => {
    const ini = DateTime.fromISO(fechas.ini).set({hour: 8});
    const fin = DateTime.fromISO(fechas.fin).plus({days: 1}).set({hour: 8});

    const iniStr = ini.toFormat('yyyy-MM-dd\'T\'HH:mm:ss');
    const finStr = fin.toFormat('yyyy-MM-dd\'T\'HH:mm:ss');
    const fechasHora = {ini: iniStr, fin: finStr}

    console.log(fechasHora);
    setFechas(fechasHora)
    const newParams = {...params, ...fechasHora}
    await getData(newParams)
  }

  const getData = async (params) => {
    setParams(params)
    await fetchData(params)
    await fetchData2(params)
    await fetchData3(params)
    changeRoute({ini: params.ini, fin: params.fin})
  }

  const heatmap = {
    series: generateEmployeeSessionData(data2),
    options: {
      chart: {
        height: 350,
        type: 'heatmap',
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#65ace3"],
      plotOptions: {
        bar: {
          borderRadius: 2,
        }
      },
    },
  }

  const actionValue = useCallback(
    (props) => {
      const ini = DateTime.fromISO(fechas.ini).toFormat('yyyy-MM-dd');
      const fin = DateTime.fromISO(fechas.fin).toFormat('yyyy-MM-dd');
      return (
        <FlexLayout justifyContent="center">
          <Button
            variant="light"
            size="xs"
            onClick={() =>
              history.push(`/crm/sessions?employeeId=${props.renderValue()}&ini=${ini}&fin=${fin}`)
            }
          >
            Ver sesiones
          </Button>
        </FlexLayout>
      );
    },
    [fechas.ini, fechas.fin, history]
  );

  const columns = useMemo(
    () => [
      {
        header: 'Operador',
        accessorKey: 'employeeName',
      },
      {
        header: 'Cantidad de Logueados',
        accessorKey: 'quantity',
      },
      {
        header: 'Acciones',
        accessorKey: 'employeeId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        cell: actionValue
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []
  )

  const colors = ["#3498db", "#e74c3c", "#f1c40f", "#2ecc71", "#9b59b6", "#34495e"]

  //TODO: sent logic to backend
  const getCoverageArray = (data) => {
    let coverageArray = []

    if (data === undefined || data == null) {
      return coverageArray
    }

    if (data.coverage == undefined) {
      return coverageArray
    }

    const textLogueados = "Logueados - " + data.coverage.toFixed(2) + "%"
    coverageArray.push({id: 1, text: textLogueados, number: data.totalCiLogged})

    const textRejected = "Rechazados - " + data.coverageRejected.toFixed(2) + "%"
    coverageArray.push({id: 2, text: textRejected, number: data.totalCiRejected})

    const textAnom = "Anónimos - " + (100 - data.coverage - data.coverageRejected).toFixed(2) + "%"
    const numberAnom = data.totalCi - data.totalCiLogged - data.totalCiRejected
    coverageArray.push({id: 3, text: textAnom, number: numberAnom})

    return coverageArray
  }

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <Controller name="ini" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <Controller name="fin" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Sesiones por empleado" type={'page'}/>

        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={handleChangeDates}/>
        </Card>

        <AsyncBlock loading={loading} error={error}>
          <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
            <CardOverview
              title={data && data.totalLogged}
              subtitle='Sesiones registradas'
              icon={<FaUsers size={20}/>}
            />
            <CardOverview
              title={data && data.totalRejected}
              subtitle='Sesiones rechazadas'
              icon={<PiSmileySad size={25}/>}
            />
            <CardOverview
              title={
                <span>
                  S/ <NumberRound>{data?.totalCi}</NumberRound>
                </span>
              }
              subtitle='Apostado Total'
              icon={<BsCashCoin size={20}/>}
            />
          </CardOverviewContainer>

          <Card padding>
            <FlexLayout column alignItems='normal'>
              <span style={{color: '#67748E', fontWeight: '600', fontSize: '.875rem'}}>Cobertura por Coin In</span>
              {data &&

                <>
                  <MultiColorProgressBar
                    data={[
                      {label: 'Logueados', value: data.totalCiLogged / data.totalCi},
                      {label: 'Rechazados', value: data.totalCiRejected / data.totalCi},
                      {
                        label: 'No participa',
                        value: (data.totalCi - data.totalCiLogged - data.totalCiRejected) / data.totalCi
                      },
                    ]}
                  />
                  <FlexLayout>
                    {
                      getCoverageArray(data).map((item, index) =>
                        <FlexLayout key={item.id} column gap='0' alignItems='center' justifyContent='center'
                                    style={{flex: 1}}>
                          <FlexLayout gap='4px' alignItems='center'>
                            <MdFiberManualRecord size={20} color={colors[index]}/>
                            <strong>
                              {item.text}
                            </strong>
                          </FlexLayout>
                          <span>
                              S/ <CiFormatter>{item.number}</CiFormatter>
                            </span>
                        </FlexLayout>
                      )
                    }
                  </FlexLayout>
                </>
              }
            </FlexLayout>
          </Card>

          <Card padding>
            <FlexLayout column alignItems='normal'>
              <span style={{color: '#67748E', fontWeight: '600', fontSize: '.875rem'}}>Metodos de logueo</span>
              {data3 &&
                <>
                  <MultiColorProgressBar
                    data={data3.map(item => {
                      return {label: item.methodName, value: item.percentage}
                    })}
                  />
                  <FlexLayout>
                    {
                      data3.map((item, index) =>
                        <FlexLayout column gap='0' alignItems='center' justifyContent='center' style={{flex: 1}}>
                          <FlexLayout gap='4px' alignItems='center'>
                            <MdFiberManualRecord size={20} color={colors[index]}/>
                            <strong>
                              {item.methodName + ' - ' + item.percentage + '%'}
                            </strong>
                          </FlexLayout>
                        </FlexLayout>
                      )
                    }
                  </FlexLayout>
                </>
              }
            </FlexLayout>
          </Card>
        </AsyncBlock>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data && data.byEmployee ? data.byEmployee : []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            filterDownload={download}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={10}
          />
        </AsyncBlock>

        <AsyncBlock loading={loading2} error={error2}>
          <Card padding>
            <Chart
              options={heatmap.options}
              series={heatmap.series}
              type="heatmap"
              height={500}
            />
          </Card>
        </AsyncBlock>
      </PageSection>

    </PageContainer>
  )
}

export default SesionesXEmpleado;
