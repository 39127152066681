import React, {useContext, useEffect, useState} from "react";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import Button from "../../../../components/forms/Button";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Card from "../../../../components/forms/Card";
import ResponsableConfiguration from "./ResponsableConfiguration";
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const SorteoConfiguration = () => {

  const {salaId} = useContext(SiteContext)
  const [params, setParams] = useState()
  const {register, handleSubmit, reset} = useForm();
  const {loading, data, error, fetchData} = useFetch2(Apis.SORTEO + '/sorteo-conf', [], params);
  const {
    data: fetchReponsables,
    loading: loading2,
    fetchData: fetchData2
  } = useFetch2(Apis.SORTEO + '/sorteo-responsable', [], params);

  const onCreate = (data) => {
    data.salaId = salaId;
    data.autoPrint = false;

    axios.put(Apis.SORTEO + "/sorteo-conf", data)
      .then(res => {
        successToast()
        fetchData()
      }).catch(err => {
        console.log(err)
        errorToast();
      })
  };

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <>
      <AsyncBlock loading={loading || loading2} error={error}>
        <Card style={{padding: '2rem', marginBottom: '2rem'}}>
          <h4 style={{fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600'}}>Acta de sorteos</h4>
          <div>
            <FormGroup>
              <label>Nro de acta correlativo</label>
              <Input {...register("nroActa")} />
            </FormGroup>
            <FormGroup>
              <label>Responsable</label>
              <Select {...register("responsableId")}>
                <option key={0} value={""} disabled>{"--Seleccionar una opción--"}</option>
                {fetchReponsables && fetchReponsables.map(item => <option key={item.id}
                  value={item.id}>{item.name}</option>)}
              </Select>
            </FormGroup>
            <FormGroup>
              <label>Administrador</label>
              <Select {...register("administradorId")}>
                <option key={0} value={""} disabled>{"--Seleccionar una opción--"}</option>
                {fetchReponsables && fetchReponsables.map(item => <option key={item.id}
                  value={item.id}>{item.name}</option>)}
              </Select>
            </FormGroup>
          </div>
          <h4 style={{fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600'}}>Sorteos virtuales</h4>
            <FormGroup>
              <label>Máximo sorteos ganados por cliente y cupón - diario</label>
              <Input {...register("maxPerDay")} />
            </FormGroup>
            <FormGroup>
              <label>Máximo sorteos ganados por cliente y cupón - por semana</label>
              <Input {...register("maxPerWeek")} />
            </FormGroup>
            <FormGroup>
              <label>Cuenta regresiva - segundos</label>
              <Input {...register("countDownSeconds")} />
            </FormGroup>
          <FormGroup>
            <label>Mostrar ganador - Hotseat</label>
            <SwitchToggle label={"Si"} {...register("showWinnerHotSeat")} />
          </FormGroup>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)} />

        </Card>
      </AsyncBlock>

      <Card style={{padding: '2rem'}}>
        <ResponsableConfiguration />
      </Card>
    </>
  )
}

export default SorteoConfiguration;
