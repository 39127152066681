import React, {useCallback, useContext, useEffect, useMemo, useState,} from "react";
import PageSection from "../../../../../components/layout/PageSection";
import {Button, Title} from "@wargostec/react-components";
import {Apis} from "../../../../../util/Apis";
import {ButtonBox} from "../../../../../components/forms/Button";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import Card from "../../../../../components/forms/Card";
import Slidebar from "../../../../common/layout/Slidebar";
import {FilterGroup} from "../../../../../components/forms/FormGroup";
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import {SiteContext} from "../../../../common/context/SiteContext";
import SecComponent from "../../../../../components/layout/SecComponent";
import tgmApi from "../../../../../apis/tgmApi";
import useLoadingToastr from "../../../../../hooks/useLoadingToastr";
import {successToast} from "../../../../../components/layout/ToastStyles";
import MachineEditComponent from "./MachineEditComponent";
import MachineNewComponent from "./MachineNewComponent";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import CardOverview, {CardOverviewContainer,} from "../../../../../components/layout/CardOverview";
import {RiErrorWarningFill} from "react-icons/ri";
import {HiOutlineSlotMachine} from "../../../../../components/assets/icons/slot-machine";
import ReactTable from "../../../../../components/util/ReactTable";
import {Tab, Tabs} from "../../../../../components/tabs/Tabs";
import SegmentCircleV2 from "../../../../crm/pages/clientes/customers/SegmentCircleV2";
import {getGenericLink} from "../../../../../util";
import {FlexLayout} from "../../../../../components/flex/Flex";
import FiltersContainer from "../../../../../components/layout/FiltersContainer";
import Select from "react-select";
import useGet from "../../../../../hooks/useGet";

const MachineListTable = ({refreshTable}) => {
  const [params, setParams] = useState(null);
  /* const { loading, data, fetchData, error } = useFetch2(
    Apis.TGM + "/machines",
    [],
    params,
    false
  ); */
  const {loading, data, fetchData, error} = useGet({
    url: Apis.TGM + "/machines",
    initialValue: [],
    params,
    auto: false,
  });

  const {register, handleSubmit, watch} = useForm();
  const [showFilter, setShowFilter] = useState(false);
  const [slideOpen, setSlideOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [idMachine, setIdMachine] = useState(null);
  const [model, setModel] = useState(0);

  const buildParams = () => {
    if (!enabled || enabled.value === null) return null;
    return {
      enabled: enabled.value,
    };
  };

  const getData = () => {
    const params = buildParams();
    fetchData(params);
  };

  /* useEffect(() => {
    if (params) {
      fetchData();
    }
  }, [params]); */

  useEffect(() => {
    const params = buildParams();
    fetchData(params);
    setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    const params = buildParams();
    fetchData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  /* useEffect(() => {
    let params = watch();
    setParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  const openSlidebar = (id) => {
    setSlideOpen(!slideOpen);
    setIdMachine(id);
  };

  const actionsValue = useCallback(
    (props) => (
      <>
        {
          <SecComponent code={"p88gnsxg"}>
            <Button
              variant="dark"
              size={"xs"}
              text={"Editar"}
              onClick={() => openSlidebar(props.row.original.id)}
            />
          </SecComponent>
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }
      </>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        header: "Nombre",
        accessorKey: "machine",
        meta: {
          headerClassName: "text-center",
          columnClassName: "text-center",
        },
      },
      {
        header: "Server",
        accessorKey: "serverLink",
        meta: {
          headerClassName: "text-center",
          columnClassName: "text-center",
        },
        cell: (props) => (
          <span>
            <FlexLayout justifyContent="center" gap="16px">
              <SegmentCircleV2
                color={getGenericLink(props.row.original.serverLink)}
              />
            </FlexLayout>
          </span>
        ),
      },
      {
        header: "Maq",
        accessorKey: "machineLink",
        meta: {
          headerClassName: "text-center",
          columnClassName: "text-center",
        },
        cell: (props) => (
          <span>
            <FlexLayout justifyContent="center" gap="16px">
              <SegmentCircleV2
                color={getGenericLink(props.row.original.machineLink)}
              />
            </FlexLayout>
          </span>
        ),
      },
      {
        header: "Fab. y modelo",
        id: "name",
        accessorFn: (row) =>
          row.slotGroup === null ? "--" : row.slotGroup?.name,
      },
      {
        header: "Juego",
        accessorKey: "gameName",
      },
      {
        header: "Serie",
        accessorKey: "serialNumber",
      },
      {
        header: "Reg.MIN",
        accessorKey: "numReg",
      },
      {
        header: "Den",
        accessorKey: "den",
      },
      // {
      //   header: 'Fab',
      //   accessorKey: 'brand'
      // },
      // {
      //   header: 'Modelo',
      //   accessorKey: 'model'
      // },
      {
        header: "Zona",
        accessorKey: "zoneName",
      },
      // {
      //   header: 'Pos',
      //   accessorKey: 'positionId'
      // },
      {
        header: "Blue",
        id: "blueSn",
        accessorFn: (row) => (row.blueSn === null ? "--" : row.blueSn),
      },
      {
        header: "Lector",
        accessorKey: "cardReaderLink",
        meta: {
          headerClassName: "text-center",
          columnClassName: "text-center",
        },
        cell: (props) => (
          <span>
            <FlexLayout justifyContent="center" gap="16px">
              <SegmentCircleV2
                color={getGenericLink(props.row.original.cardReaderLink)}
              />
            </FlexLayout>
          </span>
        ),
      },
      {
        header: "Log",
        accessorKey: "logLevel",
      },
      {
        header: "ID-0",
        accessorKey: "confId",
        meta: {
          headerClassName: "text-center",
          columnClassName: "text-center",
        },
        enableSorting: false, // para deshabilitar el sort
      },
      {
        header: "Acciones",
        accessorKey: "button",
        meta: {
          headerClassName: "text-center",
          columnClassName: "text-center",
        },
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        cell: actionsValue,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const quantityModel = (values) => {
    let model = values.filter((item) => item.slotGroup === null);
    setModel(model.length);
  };

  useEffect(() => {
    if (data) {
      quantityModel(data);
    }
  }, [data]);

  const [enabled, setEnabled] = useState({value: true, label: "Activos"});

  const clearFilters = () => setEnabled(null);

  return (
    <>
      {/* <Slidebar visible={showFilter} onHide={() => setShowFilter(false)}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <SalaIdInput {...register('salaId')} />
            <label>Estado</label>
            <Select type='text' {...register('enabled')} defaultValue={true}>
              <option value={true}>Activos</option>
              <option value={false}>De baja</option>
            </Select>
          </FormGroup>
          <Button
            variant={'primary'}
            text='Buscar'
            onClick={handleSubmit(getData)}
          />
        </div>
      </Slidebar> */}
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: "16px"}}>
          {idMachine !== null && (
            <MachineEditComponent
              id={idMachine}
              slideOpen={setSlideOpen}
              refresh={setRefresh}
            />
          )}
        </div>
      </Slidebar>

      <FiltersContainer
        handleSearch={getData}
        handlerClear={clearFilters}
        disabledSearch={!enabled}
        disabledClear={!enabled}
      >
        <SalaIdInput {...register("salaId")} />
        <FilterGroup>
          <label>Estado</label>
          <Select
            placeholder="Seleccione una opción"
            value={enabled}
            onChange={(value) => setEnabled(value)}
            options={[
              {value: true, label: "Activos"},
              {value: false, label: "De baja"},
              {value: null, label: "Todos"},
            ]}
            isClearable
          />
        </FilterGroup>
      </FiltersContainer>

      <CardOverviewContainer gridColumns="repeat(2, 1fr)">
        <CardOverview
          title={data && data.length}
          subtitle="Total"
          icon={<HiOutlineSlotMachine size={20}/>}
        />
        <AsyncBlock loading={loading} error={error}>
          <CardOverview
            title={model}
            subtitle="Máquinas sin modelo asignado"
            icon={<RiErrorWarningFill size={20}/>}
            bgcolor={"#FFC107"}
          />
        </AsyncBlock>
      </CardOverviewContainer>

      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={data || []}
          customSearch
          noData="No existen resultados para el filtro seleccionado"
          pageSize={100}
          sortArray={[{id: "machine", asc: false}]}
        />
      </AsyncBlock>
    </>
  );
};

const MachineListSummary = () => {
  return (
    <Card padding>
      <label>Resumen</label>
    </Card>
  );
};

const MachineList = () => {
  const {salaId} = useContext(SiteContext);
  const [slideOpen, setSlideOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const sync = async () => {
    await tgmApi.syncMachines(salaId);
    successToast("Máquinas sincronizadas");
  };

  const [getSync, loading] = useLoadingToastr(
    sync,
    "Error al sincronizar maquinas"
  );

  function download() {
    const downloadParams = {salaId};

    const searchParams = new URLSearchParams();
    Object.keys(downloadParams).forEach((key) =>
      searchParams.append(key, downloadParams[key])
    );

    const link = Apis.TGM + `/machines/report.xlsx?${searchParams}`;
    window.location.href = link;
  }

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Máquinas" type={"page"}/>

        <ButtonBox>
          <Button
            variant={"primary"}
            text={"Nuevo"}
            onClick={() => setSlideOpen(true)}
          />
          {/*<Button*/}
          {/*  variant={"primary"}*/}
          {/*  text={"Sincronizar"}*/}
          {/*  onClick={getSync}*/}
          {/*  disabled={loading}*/}
          {/*/>*/}
          <Button variant={"success"} text={"Exportar"} onClick={download}/>
        </ButtonBox>

        <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
          <div style={{padding: "16px"}}>
            <MachineNewComponent
              slideOpen={setSlideOpen}
              refresh={setRefresh}
            />
          </div>
        </Slidebar>

        <Tabs>
          <Tab title="General">
            <MachineListTable refreshTable={refresh}/>
          </Tab>
          <Tab title="Atributos">
            <MachineListSummary/>
          </Tab>
        </Tabs>
      </PageSection>
    </PageContainerFull>
  );
};

export default MachineList;
