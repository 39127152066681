const CompareNumericString = (rowA, rowB, id, desc) => {
  let a = Number.parseFloat(rowA.values[id]);
  let b = Number.parseFloat(rowB.values[id]);
  if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
}

export default CompareNumericString

export const extractNumber = (texto) => {
  const match = texto.match(/(\d{8}-\d+)/);
  return match ? match[1] : '';
}

export const extractTitle = (value)  => {
  const match = value.match(/^(.+?)\s\d{8}-\d+/);
  return match ? match[1].trim() : '';
}

