import styled from "styled-components";

const PointerSpan = styled.span`
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: #27a8df;
    }
`;

export default PointerSpan;
