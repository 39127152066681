import {Route, Switch} from "react-router";
import React from "react";
import CajaHome from "./CajaHome";
import CHandPayReport from "./pages/reportesOperaciones/CHandPayReport";
import CTicketReport from "./pages/reportesOperaciones/CTicketReport";
import CCardPayReport from "./pages/reportesOperaciones/CCardPayReport";
import FondoList from "./pages/fondo/lista/FondoList";
import HandPayOp from "./pages/operaciones/HandPayOp";
import Operations from "./pages/operaciones/CardPayOp";
import RefillOp from "./pages/operaciones/RefillOp";
import CRefillReport from "./pages/reportesOperaciones/CRefillReport";
import OtherPayOp from "./pages/operaciones/OtherPayOp";
import CExpensesReport from "./pages/reportesOperaciones/CExpensesReport";
import CTicketExchange from "./pages/operaciones/CTicketExchange";
import CTransferReport from "./pages/reportesOperaciones/CTransferReport";
import TransferOp from "./pages/operaciones/TransferOp";
import CTicketVentaOp from "./pages/operaciones/CTicketVentaOp";
import CTicketVentaReport from "./pages/reportesOperaciones/CTicketVentaReport";
import CBillOp from "./pages/operaciones/CBillOp";
import CBillReport from "./pages/reportesOperaciones/CBillReport";
import CSorteoReport from "./pages/reportesOperaciones/CSorteoReport";
import CInvoiceReport from "./pages/reportesOperaciones/CInvoiceReport";
import CDepositoReport from "./pages/reportesOperaciones/CDepositoReport";
import FichasMesas from "./pages/operaciones/FichasMesas";
import FichasReport from "./pages/reportesOperaciones/FichasReport";
import FondoClosed from "./pages/fondo/lista/FondoClosed";
import AuditReportBill from "./pages/audit/AuditReportBill";
import AuditCuadreFisico from "./pages/audit/AuditCuadreFisico";
import AuditSnapMeters from "./pages/audit/AuditSnapMeters";
import ConfigurationCaja from "./pages/fondo/configuration/ConfigurationCaja";
import MovimientosFondo from "./pages/fondo/MovimientosFondo";
import FundTypeList from "./pages/fondo/lista/FundTypeList";
import CashExpense from "./pages/fondo/configuration/CashExpense";
import CashExpenseEdit from "./pages/fondo/configuration/CashExpenseEdit";
import CashExpenseNew from "./pages/fondo/configuration/CashExpenseNew";
import FondoReport from "./pages/fondo/lista/FondoReport";
import AuditVentaDiaria from "./pages/audit/AuditVentaDiaria";
import AuditCuadreFisicoMachine from "./pages/audit/AuditCuadreFisicoMachine";
import AuditRecaudo from "./pages/audit/AuditRecaudo";
import AuditCuadreFisicoDiario from "./pages/audit/AuditCuadreFisicoDiario";
import CashBackReport from "./pages/reportesOperaciones/CashBackReport";

const CajaRoutes = () => {
  const parentRoute = '/caja'
  return (
    <Switch>
      <Route path={parentRoute + '/chandpay-create'} component={HandPayOp}/>
      <Route path={parentRoute + '/operations'} component={Operations}/>
      <Route path={parentRoute + '/refill-create'} component={RefillOp}/>
      <Route path={parentRoute + '/cother-create'} component={OtherPayOp}/>
      <Route path={parentRoute + '/cticket-create'} component={CTicketExchange}/>
      <Route path={parentRoute + '/ctransfer-create'} component={TransferOp}/>
      <Route path={parentRoute + '/cticket-venta'} component={CTicketVentaOp}/>
      <Route path={parentRoute + '/cbill-create'} component={CBillOp}/>

      <Route path={parentRoute + '/chandpay-report'} component={CHandPayReport}/>
      <Route path={parentRoute + '/ccardpay-report'} component={CCardPayReport}/>
      <Route path={parentRoute + '/cticket-report'} component={CTicketReport}/>
      <Route path={parentRoute + '/crefill-report'} component={CRefillReport}/>
      <Route path={parentRoute + '/cother-report'} component={CExpensesReport}/>
      <Route path={parentRoute + '/ctransfer-report'} component={CTransferReport}/>
      <Route path={parentRoute + '/cticket-venta-report'} component={CTicketVentaReport}/>
      <Route path={parentRoute + '/cash-back'} component={CashBackReport}/>
      <Route path={parentRoute + '/cbill-report'} component={CBillReport}/>
      <Route path={parentRoute + '/csorteo-report'} component={CSorteoReport}/>
      <Route path={parentRoute + '/cinvoice-report'} component={CInvoiceReport}/>
      <Route path={parentRoute + '/cdeposito-report'} component={CDepositoReport}/>
      <Route path={parentRoute + '/records-create'} component={FichasMesas}/>
      <Route path={parentRoute + '/records-report'} component={FichasReport}/>

      <Route path={parentRoute + '/audit-snap-meters'} component={AuditSnapMeters}/>
      <Route path={parentRoute + '/audit-recaudo'} component={AuditRecaudo}/>
      <Route path={parentRoute + '/audit-report-bill'} component={AuditReportBill}/>
      <Route path={parentRoute + '/audit-cuadre-fisico'} component={AuditCuadreFisico}/>
      <Route path={parentRoute + '/audit-cuadre-fisico-by-conf'} component={AuditCuadreFisicoMachine}/>
      <Route path={parentRoute + '/audit-venta-diaria'} component={AuditVentaDiaria}/>
      <Route path={parentRoute + '/audit-cuadre-fisico-diario'} component={AuditCuadreFisicoDiario}/>

      <Route path={parentRoute + '/fondo-list'} component={FondoList}/>
      <Route path={parentRoute + '/fondo-report'} component={FondoReport}/>
      <Route path={parentRoute + '/movimientos-fondo/:id'} component={MovimientosFondo}/>
      <Route path={parentRoute + '/fondo-closed/:id'} component={FondoClosed}/>
      <Route path={parentRoute + '/configuration-caja'} component={ConfigurationCaja}/>
      <Route path={parentRoute + '/expenses-category'} component={CashExpense}/>
      <Route path={parentRoute + '/expenses-category/edit/:id'} component={CashExpenseEdit}/>
      <Route path={parentRoute + '/expenses-category/new'} component={CashExpenseNew}/>
      <Route path={parentRoute + '/fund-type'} component={FundTypeList}/>

      <Route path="/" component={CajaHome}/>
    </Switch>
  )
}

export default CajaRoutes;
