import * as React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  RxCaretDown,
  RxCaretSort,
  RxCaretUp,
} from "react-icons/rx";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineViewColumns,
} from "react-icons/hi2";
import Card from "../forms/Card";
import {DropdownButton, Form, InputGroup, Table} from "react-bootstrap";
import styled from "styled-components";
import {FlexLayout} from "../flex/Flex";
import {AiOutlineCloudDownload, AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import Button from "../forms/Button";
import {BsFilter} from 'react-icons/bs';

const StyledColumn = styled.th`
  background: #F8F9FA !important;
  font-weight: 600;
  font-size: 12px;
  border-bottom: 1px solid #E9ECEF;
  padding: 0.5rem 0.25rem !important;
  white-space: nowrap !important;
  user-select: none;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : 'default')};
  ${({ sortable }) =>
    sortable &&
    `
    &:hover {
      background: #e9ecef;
    }
  `}
`

const HeaderOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #FFFFFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px;
  gap: 8px;
  flex-wrap: wrap;
`

const CustomDropDown = styled(DropdownButton)`
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    background: #F8F9FA;
    border: 1px solid #F8F9FA;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .column-span {
    @media (max-width: 768px) {
      display: none;
    }
  }
`
const RefreshSpan = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`
const CustomFormStyle = styled(Form.Control)`

  border-left: none;

  &:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
`

export default function ReactTable ({
  columns,
  data,
  sortArray = [],
  pageSize = 10,
  filterOnclick,
  filterDownload,
  filterAdd,
  filterRefresh,
  customSearch,
  customButton,
  helperText,
  noData,
  hideInitialColumns,
  styleRowClass,
  showColumnFilters = true,
}) {
  const [sorting, setSorting] = React.useState(sortArray);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState(
    hideInitialColumns
      ? hideInitialColumns.reduce((acc, column) => {
          acc[column.id] = false;
          return acc;
        }, {})
      : {}
  );
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [grouping, setGrouping] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      grouping
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getGroupedRowModel: getGroupedRowModel(),
    onGroupingChange: setGrouping,
    initialState: {
      pagination: {
        pageSize: pageSize,
      },
    },
  });

  const renderSortIndicator = (sortDirection) => {
    if (sortDirection === "asc") {
      return <RxCaretDown style={{marginLeft: "4px", display: "initial"}} />;
    } else if (sortDirection === "desc") {
      return <RxCaretUp style={{marginLeft: "4px", display: "initial"}} />;
    } else {
      return <RxCaretSort style={{marginLeft: "4px", display: "initial"}} />;
    }
  };

  const hasEnoughRows = table.getFilteredRowModel().rows.length > 10;

  return (
    <Card>
      {showColumnFilters &&
        <HeaderOptions>
          <FlexLayout alignItems='center' gap='8px' style={{flex: 1, flexWrap: 'wrap'}}>
            {filterOnclick &&
              <Button variant='dark' size='s' onClick={filterOnclick}>
                <BsFilter />
                <span>Filtrar</span>
              </Button>
            }
            {filterDownload &&
              <Button variant='light' size='s' onClick={filterDownload}>
                <AiOutlineCloudDownload />
                <span>Descargar</span>
              </Button>
            }
            {filterRefresh &&
              <Button variant='success' size='s' onClick={filterRefresh}>
                <AiOutlineReload />
                <RefreshSpan>Actualizar</RefreshSpan>
              </Button>
            }
            {filterAdd &&
              <Button variant='primary' size='s' onClick={filterAdd}>
                <AiOutlinePlus />
                <span>Nuevo</span>
              </Button>
            }
            {customButton}
            {customSearch &&
              <FlexLayout style={{flex: 1, minWidth: '100px'}}>
                <InputGroup>
                  <InputGroup.Text style={{backgroundColor: 'white', paddingRight: '2px'}}>
                    <AiOutlineSearch />
                  </InputGroup.Text>
                  <CustomFormStyle
                    type="text"
                    placeholder="Filtrar..."
                    value={globalFilter || ''}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </InputGroup>
              </FlexLayout>
            }
            {helperText &&
              <span style={{alignSelf: "center"}}>{helperText}</span>
            }
          </FlexLayout>
          <CustomDropDown
            variant='light'
            align="end"
            size="sm"
            title={
              <FlexLayout alignItems='center' gap='4px'>
                <HiOutlineViewColumns size={16} />
                <span className="column-span">Columnas</span>
              </FlexLayout>
            }
          >
            {table.getAllLeafColumns().map(column => {
              return (
                <div key={column.id} style={{padding: '0 8px'}}>
                  <label style={{fontSize: '12px'}}>
                    <input
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />{' '}
                    {column.columnDef.header}
                  </label>
                </div>
              )
            })}
          </CustomDropDown>

        </HeaderOptions>
      }
      <Table striped hover responsive>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <StyledColumn
                    key={header.id}
                    colSpan={header.colSpan}
                    sortable={header.column.getCanSort()}
                    {...{
                      className: (header.column.columnDef.meta)?.headerClassName,
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      :
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() ?
                          <>
                            {renderSortIndicator(header.column.getIsSorted())}
                          </>
                          : null
                        }
                      </>
                    }
                  </StyledColumn>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => {
              const rowClass = styleRowClass ? styleRowClass(row) : '';
              return (
                <tr key={row.id} className={rowClass}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        align={(cell.column.columnDef.meta)?.align}
                        {...{
                          className: (cell.column.columnDef.meta)?.columnClassName,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={columns.length} style={{textAlign: 'center', height: '60px',fontWeight:'bold'}}>
                {noData || 'No hay resultados'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {hasEnoughRows &&
        <FlexLayout alignItems='center' padding='16px' >
          <span style={{flex: 1}}>
            {table.getFilteredSelectedRowModel().rows.length + ' de ' + table.getFilteredRowModel().rows.length + ' seleccionadas'}
          </span>
          <FlexLayout alignItems='center'>
            <span>
              {'Pág. ' + (table.getState().pagination.pageIndex + 1) + ' de ' + table.getPageCount()}
            </span>
            <FlexLayout alignItems='center'>
              <Button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                style={{padding: 0, height: '32px', width: '32px'}}
              >
                <HiOutlineChevronDoubleLeft size={18} />
              </Button>
              <Button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                style={{padding: 0, height: '32px', width: '32px'}}
              >
                <HiOutlineChevronLeft size={18} />
              </Button>
              <Button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                style={{padding: 0, height: '32px', width: '32px'}}
              >
                <HiOutlineChevronRight size={18} />
              </Button>
              <Button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
                style={{padding: 0, height: '32px', width: '32px'}}
              >
                <HiOutlineChevronDoubleRight size={18} />
              </Button>
              <Form.Select
                size="sm"
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 50, 100, 200].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} filas
                  </option>
                ))}
              </Form.Select>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      }
    </Card>
  );
}
