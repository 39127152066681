import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import {Input} from "@wargostec/react-components";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";
import Form from "react-bootstrap/Form";

const RedeemPrint = ({info, setAction, setTitle}) => {

  const printRedeem = () => {
    return axios.get(Apis.PC + `/redeems/${info.id}/print-redeem`, {params: {printerId: getValues("printerId")}})
  }

  const {handleSubmit, register, reset, getValues} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(printRedeem))
  }, [])

  useEffect(() => {
    setTitle("Seleccione la impresora")
  }, [])

  useEffect(() => {
    if (info) {
      if (Object.keys(info).length !== 0) {
        reset(info)
      }
    }
  }, [info]);

  return (
    <Form>
      <FormGroup>
        <label>Cliente</label>
        <Input type='text' {...register("customerName")} disabled/>
      </FormGroup>
      <FormGroup>
        <label>Producto</label>
        <Input type='text' {...register("productName")} disabled/>
      </FormGroup>
      <FormGroup>
        <label>Impresora</label>
        <PrinterSelect {...register("printerId")}/>
      </FormGroup>
    </Form>
  );
};

export default RedeemPrint;
