import React, {useEffect} from "react";
import {FlexLayout} from "../../../../components/flex/Flex";
import {FormGroup, Input, Title} from "@wargostec/react-components";
import FondoActiveSelect from "../../../common/components/selects/FondoActiveSelect";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import {useForm} from "react-hook-form";

const AuditTransfer = ({id, setAction, setTitle, data3, loading3, error3, columns, fechas, saveRecaudo, monto, handleInputChange}) => {


  const {register} = useForm({});

  useEffect(() => {
    setTitle("Trasferir monto recaudado");
    setAction(() => saveRecaudo);
  }, [saveRecaudo, setAction]);

  return (
    <FlexLayout column gap='16px' alignItems='stretch' style={{width: '100%'}}>
      <Title text='Recaudo final' type='form' />
        <FormGroup>
          <label>Periodo</label>
          <Input type='text' value={fechas.period} disabled />
        </FormGroup>
        <FormGroup>
          <label>Monto</label>
          <Input type='text' value={monto} onChange={handleInputChange} />
        </FormGroup>
        <FormGroup>
          <label>Fondo</label>
          <FondoActiveSelect {...register("targetFondoId")} />
        </FormGroup>

      {data3 && data3.length > 0 &&
        <AsyncBlock loading={loading3} error={error3}>
          <ReactTable
            columns={columns}
            customSearch
            data={(data3 && data3) || []}
            noData='No existen resultados'
            pageSize={8}
            sortArray={[{id: "id", desc: true}]}
          />
        </AsyncBlock>
      }
    </FlexLayout>
  )
}

export default AuditTransfer
