import React, {useContext} from "react";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {useHistory} from "react-router";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";
import MachineSelectV2 from "../../../common/components/selects/MachineSelectV2";

const RefillOp = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const fondoSelected = localStorage.getItem('fondoSelected');
  const fondoId = fondoSelected ? JSON.parse(fondoSelected).id : null;
  const fondoName = fondoSelected ? JSON.parse(fondoSelected).name : null;
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, control, handleSubmit, watch, errors, reset, setValue} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    params.fondoId = fondoId
    axios.post(Apis.CAJA + '/refill', params).then(res => {
      successToast()
      clear()
    }).catch(err => {
      errorToast()
    })
  }

  const clear = () => {
    setValue('confId', "")
    setValue('amount', "")
    setValue('printerId', "")
  }

  return (
        <FormBlock>
          <FormGroup>
            <label>Fondo</label>
            <Input value={fondoName} disabled/>
          </FormGroup>
          <FormGroup>
            <label>Monto</label>
            <Input  {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Máquina</label>
            <Controller
              name="confId"
              control={control}
              render={({field}) => <MachineSelectV2 {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Impresora</label>
            <PrinterSelect {...register("printerId")}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
  )
}

export default RefillOp
