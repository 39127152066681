import React, {useContext, useEffect, useMemo, useState} from 'react';
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import Button from "../../../../components/forms/Button";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import {Badge} from 'react-bootstrap';
import useToggle from "../../../../hooks/useToggle";
import useEditModal from "../../../../modals/useEditModal";
import RedeemPrint from "./RedeemPrint";
import GenericModal2 from "../../../../modals/GenericModal2";
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../components/util/ReactTable';
import {DateTime} from 'luxon';
import {FlexLayout} from '../../../../components/flex/Flex';
import {LuPrinter} from 'react-icons/lu'
import PageContainerFull from '../../../../components/layout/PageContainerFull';

const RedeemPoints = () => {

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});

  const casinoDay = getCasinoDay()
  const defaultParams = {ini: casinoDay, fin: casinoDay}

  const [params, setParams] = useState(defaultParams)
  const {data, loading, error, fetchData} = useFetch2(Apis.PC + '/redeems', [], params)
  const {register, control, handleSubmit} = useForm({defaultValues: defaultParams});

  const changeState = id => axios.post(Apis.PC + '/redeems/' + id + '/change-state?state=ENTREGADO')

  const getData = (params) => {
    setParams(params)
  }

  const {
    wrapper: wrapper1,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea canjear este producto?',
    action: changeState,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.post(Apis.PC + '/redeems/' + id + '/cancel')

  const {
    wrapper: wrapper2,
    openDeleteModal: openDeleteModal2
  } = useDeleteModal({
    text: (
      <FlexLayout column>
        <strong style={{fontSize:'16px'}}>¿Deseas eliminar el canje?</strong>
        <span>Sus puntos o cupones serán devueltos.</span>
      </FlexLayout>
    ),
    action: deleteElement,
    onRefresh: fetchData
  })

  const expiredElement = id => axios.post(Apis.PC + '/redeems/' + id + '/change-state?state=EXPIRADO')

  const {
    wrapper: wrapper3,
    openDeleteModal: openExpiredModal
  } = useDeleteModal({
    text: (
      <FlexLayout column>
        <strong style={{fontSize:'16px'}}>¿Deseas expirar el canje?</strong>
        <span>Sus puntos o cupones NO serán devueltos.</span>
      </FlexLayout>
    ),
    action: expiredElement,
    onRefresh: fetchData
  })


  const download = () => {
    window.location.href = Apis.PC + `/redeems/report.xlsx?salaId=${salaId}&ini=${params.ini}&fin=${params.fin}`;
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const typeRedeem = (type) => {
    switch (type) {
      case 1:
        return 'Puntos'
      case 2:
        return 'Cupones'
      default:
        return 'Cupones'
    }
  }

  const {
    wrapper,
    addAction: printRedeem
  } = useEditModal({
    addComponent: <RedeemPrint/>,
    onRefresh: fetchData
  });

  const getBadgeByState = (state) => {
    switch (state) {
      case 'GENERADO':
        return <Badge bg="light" text="dark" style={{padding:'4px 6px'}} >{state}</Badge>;
      case 'ENTREGADO':
        return <Badge bg="" style={{backgroundColor:'#37BA32',padding:'4px 6px'}}>{state}</Badge>;
      case 'ANULADO':
        return <Badge bg="secondary" style={{padding:'4px 6px'}}>{state}</Badge>;
      case 'EXPIRADO':
        return <Badge bg="dark" style={{padding:'4px 6px'}}>{state}</Badge>;
      default:
        return <Badge bg="light" style={{padding:'4px 6px'}}>Desconocido</Badge>;
    }
  };

  const getActionsByState = (item) => {
    switch (item.state) {
      case 'GENERADO':
        return (
          <FlexLayout alignItems='center' justifyContent='flex-start' gap='4px'>
            <Button 
              variant="light" 
              size="xs" 
              title='Canjear' 
              onClick={() => openDeleteModal(item.id)}
            />
            <Button 
              variant="warning" 
              size="xs" 
              title='Expirar' 
              onClick={() => openExpiredModal(item.id)} 
            />
            <Button 
              variant="light" 
              size="xs" 
              title={<LuPrinter size={16} />} 
              onClick={() => printRedeem({
                id: item.id,
                customerName: item.customerName,
                productName: item.productName
              })} 
            />
            <Button 
              variant="danger" 
              size="xs" 
              title={'Eliminar'} 
              onClick={() => openDeleteModal2(item.id)} 
            />
          </FlexLayout>
        );
      case 'ENTREGADO':
        return (
          <FlexLayout alignItems='center' justifyContent='flex-start' gap='4px'>
            <Button 
              variant="light" 
              size="xs" 
              title={<><LuPrinter size={16}/> Imprimir</>} 
              onClick={() => printRedeem({
                id: item.id,
                customerName: item.customerName,
                productName: item.productName
              })}
            />
          </FlexLayout>
        );
        case 'EXPIRADO':
        case 'ANULADO':
          return '--';
      default:
        return '--'; 
    }
  };

  const columns = useMemo(
    () => [
      {
        header: 'Cliente',
        accessorKey: 'customerName'
      },
      {
        header: 'Nivel',
        id:'levelName',
        accessorFn: row => row.levelName ? row.levelName : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Producto',
        accessorKey: 'productName',
      },
      {
        header: 'Categoria',
        id: 'categoryName',
        accessorFn: row => row.categoryName ? row.categoryName : '--',
      },
      {
        header: 'Valor de canje',
        id:'exchangeValue',
        accessorFn: row => row.exchangeValue ? row.exchangeValue : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Tipo de canje',
        id:'type',
        accessorFn: row => row.type ? typeRedeem(row.type) : '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fecha',
        id:'date',
        accessorFn: row => row.createdAt ? DateTime.fromISO(row.createdAt).toFormat("dd/MM/yyyy") :'--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Hora',
        id:'hour',
        accessorFn: row => row.createdAt ? DateTime.fromISO(row.createdAt).toFormat("HH:mm") :'--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Canjeado por',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: '',
          columnClassName: 'font-small capitalize',
        },
      },
      {
        header: 'Estado',
        accessorKey: 'state',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => props.row.original.state ? getBadgeByState(props.row.original.state) : '--',
      },
      {
        header: 'Acciones',
        accessorKey: 'acciones',
        enableSorting: false,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: props => getActionsByState(props.row.original),
      },
      
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding:'16px'}}>
          <SalaIdInput {...register("salaId")}/>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>
      <PageSection>
        <Title text="Reporte de canjes" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            customSearch
            filterOnclick={toggler}
            filterDownload={download}
            noData='No existen canjes registrados'
            pageSize={50}
          />
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
      <DeleteGenericModal {...wrapper3}/>
      <GenericModal2 {...wrapper} />
    </PageContainerFull>
  );
};

export default RedeemPoints;
