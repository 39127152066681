import React, {useContext} from "react";
import styled from 'styled-components'
import Card from "../../../components/forms/Card";
import {SiteContext} from "../context/SiteContext";
import {useHistory, useLocation} from "react-router";
import {logoutUser} from "../../login/actions/authentication.action";
import Button from "../../../components/forms/Button";

const SalaSelectorStyled = styled.div`

  .office-container {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    padding: 30px 10px;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 230px));
    //grid-template-rows: repeat(auto-fit, minmax(150px, 230px));
    grid-template: fit-content;

    justify-content: center;
    margin: 0 auto;
  }

  .office-item {
    &:hover img {
      filter: grayscale(20%);
    }

    &:hover &__name {
      transform: scale(1.1);
      color: rgba($color: #000000, $alpha: .8);
    }

    &__logo {
      box-sizing: border-box;
      overflow: hidden;
      min-height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        transition: all .3s ease;
      }
    }

    &__name {
      font-size: 1.1rem;
      transition: all .3s ease;
      font-weight: bold;
      color: rgb(36, 40, 44);
      text-align: center;
    }
  }
`

const SalaSelector = () => {

  const history = useHistory()
  const location = useLocation();
  const {setSalaId, setInfo, moreInfo} = useContext(SiteContext)

  function select(sala) {
    setSalaId(sala.id)
    setInfo({sala: sala})
    localStorage.setItem('salaId', sala.id)
    localStorage.setItem('sala', JSON.stringify(sala))
    localStorage.setItem('tenant', moreInfo.user.company)
    localStorage.removeItem('fondoSelected')

    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect')

    if (redirect) {
      history.push(redirect)
    } else {
      history.push('/')
    }
  }

  const getLogoURL = (logoUrl) => {
    if (logoUrl === undefined || logoUrl === null || logoUrl === '') {
      return 'https://res.cloudinary.com/dghqjv5az/image/upload/v1669488290/mix/logossala/logo_xd33n7.png'
    }

    return 'https://res.cloudinary.com/dghqjv5az/image/upload/c_fill,h_120/linkedwgt/' + logoUrl
  }

  return (
    <React.Fragment>
      <h1 style={{
        fontSize: "24px",
        textAlign: "center",
        paddingTop: "40px",
        marginBottom: "34px"
      }}>Seleccione una sala
      </h1>

      <div className="button-exit" style={{display: "flex", justifyContent: "center", marginBottom: "24px"}}>
        <Button variant='dark' onClick={logoutUser}>Cerrar Sesión</Button>
      </div>

      <SalaSelectorStyled>
        <div className="office-container" style={{overflow: "auto", height: "75vh"}}>

          {moreInfo && moreInfo.salas && moreInfo.salas.map(item =>
            <Card key={item.id} padding style={{cursor: 'pointer', maxHeight: "220px"}} onClick={() => select(item)}>
              <div className='office-item'>

                <div className="office-item__logo">
                  <img src={getLogoURL(item.logo)} alt='item logo'/>
                </div>
                <div className="office-item__name">
                  <span>{item.name}</span>
                </div>

              </div>
            </Card>
          )}
        </div>
      </SalaSelectorStyled>
    </React.Fragment>
  )
}

export default SalaSelector;
