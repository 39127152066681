import React, {useContext, useState} from "react";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {SiteContext} from "../../../common/context/SiteContext";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import FondoActiveSelect from "../../../common/components/selects/FondoActiveSelect";
import {FaRightLeft} from "react-icons/fa6";


const TransferOp = () => {

  const {salaId} = useContext(SiteContext)
  const fondoSelected = localStorage.getItem('fondoSelected');
  const fondoId = fondoSelected ? JSON.parse(fondoSelected).id : null;
  const fondoName = fondoSelected ? JSON.parse(fondoSelected).name : null;
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});
  const [invert, setInvert] = useState(false)

  const onCreate = (params) => {
    if (invert) {
      params.sourceFondoId = params.targetFondoId
      params.targetFondoId = fondoId
    } else {
      params.sourceFondoId = fondoId
    }
    params.salaId = salaId
    params.printerId = null
    axios.post(Apis.CAJA + '/fondos/transfer/v2', params)
      .then(res => {
        successToast()
      }).catch(err => {
      errorToast()
    })
  }

  return (
    <FormBlock>
      <Row>
        <Col>
          <FormGroup>
            <label>Fondo origen</label>
            {
              invert ?
                <FondoActiveSelect {...register("sourceFondoId")}/> :
                <Input value={fondoName} disabled/>
            }
          </FormGroup>
        </Col>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'transform 0.6s',
          transform: invert ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
             onClick={() => setInvert(!invert)}>
          <FaRightLeft size={28}/>
        </div>
        <Col>
          <FormGroup>
            <label>Fondo destino</label>
            {
              invert ?
                <Input value={fondoName} disabled/> :
                <FondoActiveSelect {...register("targetFondoId")}/>
            }
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <label>Monto</label>
        <Input {...register("amount")}/>
      </FormGroup>

      <ButtonBox>
        <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
      </ButtonBox>
    </FormBlock>
  )
}

export default TransferOp;
