import React, {useContext, useEffect, useRef, useState} from 'react'
import {Card} from "@wargostec/react-components";
import Input from '../../../../components/forms/FormEl'
import Button, {ButtonBox} from '../../../../components/forms/Button'
import {LuDownload, LuUpload} from 'react-icons/lu'
import {Table} from 'react-bootstrap'
import {FlexLayout} from '../../../../components/flex/Flex'
import {numberFormat} from '../../../../components/util/NumberUtil'
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {Controller, useForm} from 'react-hook-form'
import FileUploader from '../../../common/components/FileUploader'
import {BsFillCheckCircleFill, BsFillExclamationCircleFill} from 'react-icons/bs'
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import useModal from "../../../../modals/useModal";
import ModalEventBill from "../../../tgm/pages/realtime/ModalEventBill";
import ModalInfo from "../../../../modals/ModalInfo";
import useEditModal from "../../../../modals/useEditModal";
import ModalSaveTicketRecaudo from "../../../tgm/pages/realtime/ModalSaveTicketRecaudo";
import GenericModal2 from "../../../../modals/GenericModal2";

const AuditRecaudoBill = ({fechas}) => {

  const {salaId} = useContext(SiteContext);
  const [inputs, setInputs] = useState({});
  const inputRefs = useRef([]);
  const {control, getValues, watch} = useForm({});

  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/report-bill`, null, fechas);

  useEffect(() => {
    // Deshabilitar el scroll del mouse en los inputs de tipo number
    const handleWheel = (event) => {
      if (document.activeElement.type === 'number') {
        event.preventDefault();
      }
    };
    window.addEventListener('wheel', handleWheel, {passive: false});
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const download = () => {
    const link = Apis.CAJA + '/audit-bill/report.xlsx?salaId=' + salaId;
    window.location.href = link;
  }

  const watchDocument = watch("document")

  const handleUpload = async () => {
    if (watchDocument) {
      const formBody = {
        file: getValues('document'),
        salaId: salaId,
        fecha: fechas.ini
      };
      try {
        await axios.post(Apis.CAJA + '/audit-bill/import-data', formBody);
        successToast();
        fetchData();
      } catch (error) {
        errorToast();
      }
    }
  };

  const handleInputChange = (index, value) => {
    setInputs((prev) => ({ ...prev, [index]: value }));
  };

  const handleKeyPress = async (event, machine, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const body = {
        salaId: salaId,
        periodo: fechas.period,
        amount: inputs[index] !== undefined ? inputs[index] : machine.billAudit,
        confId: machine.confId
      };
      try {
        await axios.post(Apis.CAJA + '/audit-bill', body);
        successToast();
      } catch (error) {
        errorToast();
      }
      let nextIndex = index + 1;
      while (inputRefs.current[nextIndex] && inputRefs.current[nextIndex].disabled) {
        nextIndex++;
      }
      const nextInput = inputRefs.current[nextIndex];
      if (nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    }
  };

  const {
    wrapper: wrapper2,
    someAction: modalEventsBill,
  } = useModal({
    component: <ModalEventBill/>,
  });

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line
  }, [fechas])

  const handleSearch = () => {
    fetchData()
  }

  const {wrapper, addAction: modalSaveTicketRecaudo} = useEditModal({
    addComponent: <ModalSaveTicketRecaudo/>,
    onRefresh: fetchData
  });

  return (
    <>
      <ButtonBox>
          <Button variant={'dark'} onClick={download}>
            <span>Descargar plantilla</span>
            <LuDownload size={18}/>
          </Button>
          <Controller name="document" control={control}
                      render={({field}) => <FileUploader folder={'coupons'} {...field}/>}/>
          {watchDocument &&
            <Button onClick={handleUpload}>
              <span>Cargar excel</span><LuUpload size={18}/>
            </Button>
          }
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          <Card noPadding>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th className="text-center">Máquina</th>
                  <th className="text-center">Billetero físico S/</th>
                  <th className="text-right">Prebill</th>
                  <th className="text-right">Billetero electrónico S/</th>
                  <th className="text-center">Estado</th>
                  <th className="text-right">Faltante</th>
                  {
                    (salaId === 221  || salaId === 999) &&
                    <th className="text-right">Tickets</th>
                  }
                  <th className="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {data && data.data.sort((a, b) => a.machine.localeCompare(b.machine)).map((machine, index) => {
                  const billAuditValue = inputs[index] !== undefined ? parseFloat(inputs[index]) : machine.billAudit;
                  return (
                    <tr key={machine.confId}>
                      <td className="text-center fw-bold">
                        {machine.machine}
                      </td>
                      <td className="text-center">
                        <FlexLayout justifyContent='center'>
                          <Input
                            type="number"
                            style={{maxWidth: '140px', textAlign: 'center'}}
                            value={inputs[index] !== undefined ? inputs[index] : machine.billAudit}
                            id={`billNewInput-${index}`}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e, machine, index)}
                            disabled={data && data.audited}
                            ref={(el) => (inputRefs.current[index] = el)}
                          />
                        </FlexLayout>
                      </td>
                      <td className="text-right">{numberFormat(machine.prebillAudit)}</td>
                      <td className="text-right">{numberFormat(machine.billOnline)}</td>
                      <td className="text-center">
                        {
                          machine.collected === true &&
                          <>
                          {(billAuditValue.toFixed(2) === machine.expected.toFixed(2)) ?
                            <BsFillCheckCircleFill size={20} color='#16a34a'/>
                            :
                            <BsFillExclamationCircleFill size={20} color='#fbbf24'/>
                        }
                          </>
                        }
                      </td>
                      <td className="text-right"  style={ {color: (machine.diff < 0 && 'red') }}>
                        {numberFormat(machine.diff)}
                      </td>
                      {
                        (salaId === 221  || salaId === 999 || salaId === 120) &&
                        <td className="text-right">{numberFormat(machine.ticketAudit)}</td>
                      }
                      <td>
                        <FlexLayout justifyContent='center'>
                          <Button variant='light' size='xs'
                                  onClick={() => modalEventsBill({
                                    values: {
                                      confId: machine.confId,
                                      ini: fechas.period,
                                      fin: fechas.period,
                                      machine: machine.machine,
                                    }
                                  })}>Billetes
                          </Button>
                          <Button variant='warning' size={'xs'}
                                  onClick={() => modalSaveTicketRecaudo({
                                    confId: machine.confId,
                                    machine: machine.machine,
                                    period: fechas.period,
                                  })}>
                            Tickets
                          </Button>
                        </FlexLayout>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </AsyncBlock>
      <ModalInfo {...wrapper2} size='lg'/>
      <GenericModal2 {...wrapper} size='xl'/>
    </>
  )
}

export default AuditRecaudoBill
