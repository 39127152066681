import React, {useContext} from "react";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import OtherPayCategorySelect from "../../../common/components/selects/OtherPayCategorySelect";
import {successToast} from "../../../../components/layout/ToastStyles";

const OtherPayOp = () => {

  const {salaId} = useContext(SiteContext)
  const fondoSelected = localStorage.getItem('fondoSelected');
  const fondoId = fondoSelected ? JSON.parse(fondoSelected).id : null;
  const fondoName = fondoSelected ? JSON.parse(fondoSelected).name : null;
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit,reset} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.fondoId = fondoId
    params.salaId = salaId
    params.printerId = null
    axios.post(Apis.CAJA + '/operation-other', params)
      .then(res => {
        successToast("Se ha guardado correctamente")
        reset({amount: '', description: '', expenseCategoryId: '', receivedBy: ''})
      }).catch(err => {
      console.log(err)
    })
  }

  return (
        <FormBlock>
          <FormGroup>
            <label>Fondo</label>
            <Input value={fondoName} disabled/>
          </FormGroup>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Descripción</label>
            <textarea
              placeholder={"Describa el concepto de gasto"}
              {...register("description")}
              style={{
                width: "100%",
                display: "inline-block",
                fontSize: "1rem",
                backgroundColor: "#fff",
                backgroundImage: "none",
                border: "1px solid #dcdfe6",
                height: "150px"
              }}
            />
          </FormGroup>
          <FormGroup>
            <label>Categoría</label>
            <OtherPayCategorySelect {...register("expenseCategoryId")} form={true}/>
          </FormGroup>
          <FormGroup>
            <label>Recibido por (Opcional)</label>
            <Input {...register("receivedBy")} placeholder={"Ejm: Juan, Sr. Perez, Sra. Julia, etc"}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
  )
}

export default OtherPayOp
