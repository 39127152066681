import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import Form from "react-bootstrap/Form";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button from "../../../../components/forms/Button";
import Card from "../../../../components/forms/Card";
import {SiteContext} from "../../../common/context/SiteContext";
import MachineSelectV2 from "../../../common/components/selects/MachineSelectV2";
import {FlexLayout} from "../../../../components/flex/Flex";
import Moment from "react-moment";
import {formatNumber} from "../../../../components/util/NumberUtil";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import {FlexContainer} from "@wargostec/react-components";
import {LuCopy} from "react-icons/lu";

const TicketCreate = () => {

  const {salaId} = useContext(SiteContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validVn, setValidVn] = useState(false);
  const [message, setMessage] = useState('');

  const {
    register, control, setValue, getValues
  }
    = useForm({});

  const onCreate = (params) => {
    if (!getValues('vn')) {
      setValidVn(true);
      setMessage('Campo obligatorio');
      return;
    }

    if (getValues('vn').length !== 18) {
      setValidVn(true);
      setMessage('El número de ticket debe tener 18 dígitos');
      return;
    }

    axios.post(Apis.TICKET + '/ticket-by-caja/create', params)
      .then(res => {
        successToast("Operación realizada correctamente", 2000)
        clear()
      }).catch(err => {
      errorToast("Ocurrió un problema al guardar. Intente de nuevo en unos minutos.", 2000)
    })
  }

  const clear = () => {
    setValue('vn', "")
    setValue('confId', "")
  }

  const handleMachineChange = () => {
    const confId = getValues('confId')
    getPossibleTickets(confId)
  }

  const getPossibleTickets = (confId) => {
    setLoading(true)
    axios.get(Apis.CAJA + '/operation-ticket/by-conf', {
      params: {
        confId: confId,
      }
    }).then(res => {
      console.log(res.data)
      setData(res.data)
      setLoading(false)
    }).catch(err => {
      errorToast();
      setLoading(false)
    })
  }

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const validNumberValue = useCallback((props) => {
    const renderValue = props.renderValue();

    const copyToClipboard = () => {
      if (renderValue) {
        navigator.clipboard.writeText(renderValue)
          .then(() => {
            successToast('Texto copiado al portapapeles', 200);
          })
          .catch((err) => {
            errorToast('Error al copiar el texto: ', err);
          });
      }
    };

    return (
      <FlexContainer column gap='4px' alignItems='center' justifyContent='center'>
        {renderValue ?
          <>
            <span>
              {renderValue}
            </span>
            <Button
              size='xs'
              variant='light'
              style={{padding: '8px'}}
              onClick={copyToClipboard}
            >
              <LuCopy size={10}/>
            </Button>
          </>
          :
          <span>--</span>
        }

      </FlexContainer>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'N° Ticket',
        accessorKey: 'validNumber',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: validNumberValue
      },
      {
        header: 'Creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Monto',
        id: 'amount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: props => <>{props.row.original.amount ? formatNumber(props.row.original.amount, 'S/') : '--'}</>
      },
      {
        header: '',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            {!info.row.original.created ?
              <Button variant='success' size='xs'
                      onClick={() => onCreate({
                        ...info.row.original,
                        vn: getValues('vn').substring(2),
                        validationType: 0,
                        amount: info.row.original.amount * 100,
                        validationSystemId: getValues('vn').substring(0, 2)
                      })}
              >Crear</Button>
              :
              <span className='text-xs'>Ticket ya existe</span>
            }
          </div>
        ),
      }
    ], []
  );

  return (
    <>
      <Card padding>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <label>Número de ticket</label>
                <Input type="number" {...register("vn", {required: "Campo obligatorio"})}/>
                {validVn && <p style={{color: 'red'}}>{message}</p>}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Máquina</label>
                <Controller
                  name="confId"
                  control={control}
                  render={({field}) => (
                    <MachineSelectV2
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        handleMachineChange();
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card padding>
        <AsyncBlock loading={loading}>
          <ReactTable
            columns={columns}
            data={data || []}
            noData='No existen tickets posibles, seleccione algúna máquina u otra máquina'
            pageSize={15}
            showColumnFilters={false}
          />
        </AsyncBlock>
      </Card>
    </>
  );
};

export default TicketCreate;
