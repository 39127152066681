import React from "react";
import Loader1 from "../../modules/common/components/Loader1";
import ErrorCard from "./ErrorCard";
import styled from "styled-components";

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 10;
`;

const Container = styled.div`
  position: relative;
  width:100%;
  height:100%;
`;

const AsyncBlock = ({ children, loading, error, showLoader = true }) => {
  const content = error ? <ErrorCard axiosError={error}/> : children;

  return (
    loading && showLoader
      ?
      <Loader1/>
      :
      content
  );
};

export default AsyncBlock;