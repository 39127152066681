import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './indexAlgolia.css';
import {datadogRum} from "@datadog/browser-rum";
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

datadogRum.init({
  applicationId: '43a81377-9af7-4131-baa6-a3ed0b6df04e',
  clientToken: 'pub3f31e9892120a36d23326b259ad38e59',
  site: 'datadoghq.com',
  service: 'front-wgt-dashboard',
  env: 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingOrigins: ["https://app.wargos.cloud", "https://api4.wargos.cloud"]
});

datadogRum.startSessionReplayRecording();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
