import { DateTime } from "luxon";
import moment from "moment";

const getCasinoRange = () => {
  const now = DateTime.now();
  const referenceTime = now.set({hour: 8, minute: 0, second: 0, millisecond: 0});
  const ini = now < referenceTime
    ? now.minus({days: 1}).set({hour: 8, minute: 0, second: 0, millisecond: 0}).toFormat("yyyy-MM-dd'T'HH:mm:ss")
    : referenceTime.toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const fin = referenceTime.plus({days: 1}).toFormat("yyyy-MM-dd'T'HH:mm:ss");

  return {ini, fin};
};

const getCasinoDay = () => {
  let ini = DateTime.now()
  const hour = ini.hour

  if (hour < 8) {
    ini = ini.minus({ day: 1 })
  }

  return ini.toISODate()
}

const iniFinCasinoTimes = (casinoDay) => {
  const fecha = DateTime.fromISO(casinoDay);
  // Obtiene la fecha con hora 8 AM del día actual
  const fechaActual8AM = fecha.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  // Obtiene la fecha del día siguiente
  const fechaSiguiente = fechaActual8AM.plus({ days: 1 });

  return [fechaActual8AM.toISO(), fechaSiguiente.toISO()];
}

const getDateFilter = (mode) => {
  let date = {}
  if (mode === 'day') {
    const today = moment().format('YYYY-MM-DD')
    date = {
      ini: today,
      fin: today,
    }
  } else if (mode === 'month') {
    const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    date = {
      ini: firstDayOfMonth,
      fin: lastDayOfMonth,
    }
  }

  return date
}

const getStartDateTimeFromRaffle = (fechaHora, relativePeriod, relativePeriodAmount) => {
  let momentPeriod = 'hours'

  if (relativePeriod === 'WEEK') {
    momentPeriod = 'weeks'
  } else if (relativePeriod === 'DAY') {
    momentPeriod = 'days'
  } else if (relativePeriod === 'MONTH') {
    momentPeriod = 'months'
  }

  return moment(fechaHora).add(-1 * relativePeriodAmount, momentPeriod).format('DD/MM HH:mm');
}

export { getCasinoDay, getDateFilter, getStartDateTimeFromRaffle, iniFinCasinoTimes }

export default getCasinoRange

