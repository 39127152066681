import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Input} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {HiOutlineSlotMachine} from "../../../../components/assets/icons/slot-machine";
import {HiOutlineBanknotes} from "react-icons/hi2";
import ReactTable from "../../../../components/util/ReactTable";
import {FlexLayout} from "../../../../components/flex/Flex";
import Moment from "react-moment";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {LuAlertCircle} from "react-icons/lu";
import {formatNumber} from "../../../../components/util/NumberUtil";
import {useForm} from "react-hook-form";
import useFetch2 from "../../../../hooks/useFetch2";

export const getStatusText = (status, validationType) => {
  let statusText = '';

  if (validationType === 34) {
    statusText = 'BLOQUEADO';
  } else {
    switch (status) {
      case 0:
        statusText = 'PENDIENTE';
        break;
      case 1:
        statusText = 'CANJEADO';
        break;
      case 2:
        statusText = 'EXPIRADO';
        break;
      case 4:
        statusText = 'IMPRESION PENDIENTE';
        break;
      case 5:
        statusText = 'IMPRESION EN PROGRESO';
        break;
      case 6:
        statusText = 'IMPRESION FALLO';
        break;
      case 7:
        statusText = 'IMPRESION BLOQUEADO';
        break;
      default:
        statusText = '--';
        break;
    }
  }
  return statusText;
};

const ModalSaveTicketRecaudo = ({info, setAction, setTitle}) => {

  const defaultParams = {period : info.period, confId : info.confId}
  const {data} = useFetch2(Apis.CAJA + '/audit-ticket/by-conf', [], defaultParams);

  const [ticketCode, setTicketCode] = useState('');
  const [ticketHistory, setTicketHistory] = useState([]);
  const inputRef = useRef(null);

  const totalAmount = useMemo(() => {
    let total = 0;
    ticketHistory.forEach(ticket => {
      if (ticket.validationType !== 34) total += ticket.amount;
    });
    return total;
  }, [ticketHistory])

  const totalTickets = useMemo(() => {
    let total = 0;
    ticketHistory.forEach(ticket => {
      if (ticket.validationType !== 34) total++;
    });
    return total;
  }, [ticketHistory])

  useEffect(() => {
    if (data) {
      setTicketHistory(data);
    }
  }, [data])

  const compareTickets = (ticket1, ticket2) => {
    const num1 = parseInt(ticket1, 10);
    const num2 = parseInt(ticket2, 10);
    return num1 === num2;
  };

  const getConsultTicket = () => {
    if (ticketHistory.some(ticket => ticket.ticketCode === ticketCode ||compareTickets(ticket.validNumber, ticketCode))) {
      successToast("Ticket ya agregado", 2000);
      inputRef.current.focus();
      setTicketCode('');
      return;
    }

    axios.get(Apis.CAJA + '/operation-ticket/valid-number/' + ticketCode).then(res => {
      const ticketData = res.data;
      if (ticketData.exists) {
        successToast("Ticket agregado!", 2000);
        const ticketInfo = ticketData.ticket;
        const ticketObject = {
          ticketCode,
          id: ticketInfo.id,
          validNumber: ticketInfo.validNumber,
          confId: ticketInfo.confId,
          machine: ticketInfo.machine,
          confIdRedeem: ticketInfo.confIdRedeem,
          machineRedeem: ticketInfo.machineRedeem,
          amount: ticketInfo.amount,
          status: ticketInfo.status,
          createdAt: ticketInfo.createdAt,
          redeemAt: ticketInfo.redeemAt,
          fondoIdRedeem: ticketInfo.fondoIdRedeem,
          fondoNameRedeem: ticketInfo.fondoNameRedeem,
          validationType: ticketInfo.validationType
        };

        setTicketHistory(prevHistory => [ticketObject, ...prevHistory]);
      } else {
        errorToast("Ticket no encontrado", 2000);
      }

      inputRef.current.focus();
      setTicketCode('');
      return res.data;
    }).catch(error => {
      let errorMessage = 'Ticket no encontrado';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      errorToast(errorMessage, 2000);
      inputRef.current.focus();
      setTicketCode('');
      throw error;
    })
  }

  const dateValue = useCallback(props => <>{
    <FlexLayout alignItems='left' justifyContent='left' gap='4px'>
      <span><Moment format={'HH:mm DD/MM/YYYY'}>{props.row.original.eventDate}</Moment></span>
    </FlexLayout>
  }</>, [])

  const {handleSubmit} = useForm();

  const columns = useMemo(
    () => [
      {
        header: 'Estado',
        accessorKey: 'getstatus',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ({row}) => (getStatusText(row.original.status, row.original.validationType)),
      },
      {
        header: 'Numero de ticket',
        accessorKey: 'validNumber'
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: props => <>{props.row.original.amount ? formatNumber(props.row.original.amount * 0.01, 'S/') : '--'}</>
      },
      {
        header: 'Tipo',
        accessorKey: 'validationType',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.renderValue() === 0 ? 'CASHOUT' : 'PROMOCIONAL'}</>
      },
      {
        header: 'Emitido en',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: props => <>{props.row.original.machine ? props.row.original.machine : '--'}</>
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Canjeado en',
        accessorKey: 'redeemBy',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          info.row.original.confIdRedeem !== null && info.row.original.confIdRedeem !== 0
            ? info.row.original.machineRedeem
            : info.row.original.fondoNameRedeem
        )
      },
      {
        header: 'Fecha de canje',
        accessorKey: 'redeemAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: '',
        accessorKey: 'checkstatus',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (info.row.original.status === 0 ?
          <LuAlertCircle size={22} className='text-amber-400 animate-bounce'/> : null),
      },
    ]
    , [])

  const saveTicketRecaudo = () => {
    const filteredTickets = ticketHistory.filter(ticket => {
      return !data.some(dataTicket => dataTicket.id === ticket.id);
    });

    const amount = filteredTickets.reduce((acc, ticket) => {
      const amount = Number(ticket.amount) || 0;
      return acc + amount;
    }, 0);

    const body = {
      amount: amount * 0.01,
      confId: info.confId,
      period: info.period,
      tickets : filteredTickets.map(ticket => ({
        id: ticket.id,
        vn: ticket.validNumber,
      }))
    }
    return axios.post(Apis.CAJA + '/audit-ticket', body);
  }

  useEffect(() => {
    setTitle("Recaudo de tickets");
  }, [])

  useEffect(() => {
    if (ticketHistory.length > 0) {
      setAction(() => handleSubmit(saveTicketRecaudo));
    }
  }, [ticketHistory]);

  return (
    <>
      <Card>
        <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
          <CardOverview
            title={info?.machine ?? "---"}
            subtitle='Maquina'
            icon={<HiOutlineSlotMachine size={20}/>}
          />
          <CardOverview
            title={'S/ ' + totalTickets ? formatNumber(totalTickets, null) : '--'}
            subtitle='Cantidad'
            icon={<HiOutlineBanknotes size={20}/>}
          />
          <CardOverview
            title={'S/ ' + totalAmount ? formatNumber(totalAmount * 0.01, 'S/') : '--'}
            subtitle='Total'
            icon={<HiOutlineBanknotes size={20}/>}
          />
        </CardOverviewContainer>
      </Card>

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '18px'}}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getConsultTicket();
          }}
          style={{flexGrow: 1, marginRight: '10px'}}
        >
          <Input
            placeholder="Nro. de ticket"
            type='5444830802677878'
            id='auto-focus-input'
            ref={inputRef}
            value={ticketCode}
            autoFocus
            required
            autoComplete="off"
            onChange={(e) => setTicketCode(e.target.value)}
            style={{width: '100%'}}
          />
        </form>
        <div>
          <Button type="submit" text='Agregar' size='lg' disabled={ticketCode === ''}/>
        </div>
      </div>
      <Card>
        <ReactTable
          columns={columns}
          data={ticketHistory || []}
          customSearch
          noData='No existen resultados'
          pageSize={15}
          showColumnFilters={false}
        />
      </Card>
    </>
  );
};

export default ModalSaveTicketRecaudo;
