import styled from "styled-components";

const FilterGroup = styled.div`
  width: 250px;
  margin-bottom: 1rem;

  label{
    margin-bottom: 0.2rem;
    font-weight: 600;
  }
`

const FormGroup = styled.div`
  //margin : 5px 0;
  width: 100%;
  margin-bottom: 1rem;

  label {
    margin-bottom: 0.2rem;
    font-weight: 600;
  }
`

const FormBlock = styled.div`
  background-color: #ffffff;
  padding: 1.8rem;
  margin-bottom: 1rem;
  
  box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, .05), 0 1px 3px 0 rgba(63, 63, 68, .15));
  outline: .1rem solid transparent;
`

const FormBlockTitle = styled.label`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
`

export { FormBlock, FormBlockTitle, FilterGroup };

export default FormGroup;
